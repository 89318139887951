import { styled } from '@mui/material';
import TextComponent from './TextComponent';
import RetirementComponent from './RetirementComponent';
import CloseIcon from '../../../core/assets/module/sheet/close.svg';
import TickIcon from '../../../core/assets/module/sheet/tick.svg';
import SearchIcon from '../../../core/assets/module/sheet/search.svg';
import LinkWrapperComponent from './LinkWrapperComponent';
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { useRef, useEffect } from 'react';

const StandartBottomSheetComponent = ({
    open,
    onDismiss,
    onSubmit,
    submitIcon,
    children,
    title,
    description,
    zIndex = 2,
    search = false
}: {
    open: boolean;
    onDismiss: any;
    onSubmit?: any;
    submitIcon?: string;
    children: any;
    title: string;
    description?: string;
    zIndex?: number;
    search?: boolean
}) => {
    // focus
    const dummyElementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (open && dummyElementRef.current) {
            dummyElementRef.current.focus();
        }
    }, [open]);

    // style
    const SheetHeaderStyled = styled('div')({
        display: 'grid',
        gridTemplateColumns: '30px 1fr 30px',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 20
    });

    // widget
    return (
        <BottomSheet
            scrollLocking={!/iPhone|iPad|iPod/i.test(navigator.userAgent)}
            autoFocus={false}
            open={open}
            style={{ zIndex: zIndex }}
            onDismiss={onDismiss}
            header={
                <SheetHeaderStyled>
                    <LinkWrapperComponent onClick={() => onDismiss(false)}>
                        <img style={{ textAlign: 'left' }} src={CloseIcon} alt="close icon" />
                    </LinkWrapperComponent>
                    <div style={{ textAlign: 'center' }}>
                        <TextComponent type='default'>{title}</TextComponent>
                    </div>
                    {
                        onSubmit
                            ? <LinkWrapperComponent onClick={onSubmit}>
                                <img style={{ textAlign: 'right' }} src={submitIcon ?? (search ? SearchIcon : TickIcon)} alt="action icon" />
                            </LinkWrapperComponent>
                            : null
                    }
                </SheetHeaderStyled>
            }
        >
            <div>
                <div tabIndex={0} ref={dummyElementRef} style={{ width: 0, height: 0, overflow: 'hidden' }} />
                {
                    description
                        ? (<div style={{ textAlign: 'center' }}>
                            <TextComponent type='small'>
                                {description}
                            </TextComponent>
                            <RetirementComponent />
                        </div>)
                        : null
                }
                {children}
            </div>
        </BottomSheet>
    );
}

export default StandartBottomSheetComponent;
import TextComponent from "./TextComponent";
import StandartBottomSheetComponent from "./StandartBottomSheetComponent";
import TemplateConfig from "../../../core/config/TemplateConfig";

export default function ConfirmBottomSheetComponent({ open, onDismiss, onSubmit, description, title = 'Предупреждение' }: { open: any; onDismiss: any; onSubmit: any; description: string; title?: string }) {
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={title} onSubmit={onSubmit}>
            <div style={{ textAlign: 'center' }}>
                <TextComponent weight={TemplateConfig.lightweightText}>{description}</TextComponent>
            </div>
        </StandartBottomSheetComponent>
    );
}
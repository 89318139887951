import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function TransferService({ amount, recipientNumber }: { amount: number; recipientNumber: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "balance",
        "process": "transfer",
        "accessToken": accessToken,
        "amount": amount.toString(),
        "recipientNumber": recipientNumber
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );

    // check result
    if (!result || !result.data) return;

    // open link
    if (result.status === 'succes') {
        partnerDataStore.reduceBalance(Number(amount));
        toast("Операция прошла успешно", { type: "success" });
    }
}
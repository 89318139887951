import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import { HistoryDataResponse } from "../../balance/model/HistoryDataResponse";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import adminProceduresStore from "../store/ProceduresStore";
export default async function GetProceduresService() {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "procedures",
        "accessToken": accessToken,
        "ownerId": adminProceduresStore.ownerId,
        "page": adminProceduresStore.currentPage.toString()
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );

    // check result
    if (!result || !result.data) return;

    // save history
    if ((result.data as HistoryDataResponse).procedures.length === 0) {
        adminProceduresStore.updateLoadAviable(false);
        if (adminProceduresStore.currentPage !== 1) toast("Все данные загружены", { type: "success" });
        return result.data;
    }
    adminProceduresStore.push((result.data as HistoryDataResponse).procedures);
    return result.data;
}
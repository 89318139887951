import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import QRCode from "../../../core/assets/module/purchase/qr-code.png";

const ScanCodeComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="QR-код" description="После завершения оплаты, пожалуйста, отправьте чек вашему куратору.">
            <div style={{ textAlign: 'center' }}>
                <img style={{ width: '65%' }} src={QRCode} alt="qr code" />
            </div>
        </StandartBottomSheetComponent>
    );
}

export default ScanCodeComponent;
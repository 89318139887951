import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import adminWithdrawalStore from "../store/WithdrawalStore";
export default async function SolveWithdrawalService({ customerId, withdrawalId, index, result }: { customerId: string; withdrawalId: string; index: number, result: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "solveWithdrawal",
        "accessToken": accessToken,
        "withdrawalId": withdrawalId,
        "pointCustomerId": customerId,
        "result": result
    };
    var requestResult = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );

    // check result
    if (!requestResult || !requestResult.data) return;

    // update history
    adminWithdrawalStore.removeItem(index);
    toast("Операция прошла успешно", { type: "success" });
}
export default function CreditDateFormatUtil(value: string) {
    const cleaned = value.replace(/\D/g, ''); // Удаляем все нецифровые символы
    let formattedDate = '';

    // Добавляем первые два символа для дня (DD)
    let day = cleaned.slice(0, 2);
    if (day.length === 2) {
        const dayNumber = parseInt(day, 10);
        if (dayNumber > 31) day = '31'; // Ограничение максимального значения для дня
        if (dayNumber < 1) day = '01'; // Ограничение минимального значения для дня
    }
    formattedDate += day;

    // Добавляем разделитель, если день уже полностью введен
    if (cleaned.length > 2) {
        formattedDate += ' / ';
    }

    // Добавляем последние два символа для года (YY)
    let year = cleaned.slice(2, 4);
    if (year.length === 2) {
        const yearNumber = parseInt(year, 10);
        if (yearNumber < 24) year = '24'; // Ограничение минимального значения для года
    }
    formattedDate += year;

    return formattedDate;
}

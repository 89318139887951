import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import AccessTokenVerifyService from "./AccessTokenVerifyService";
import customerDataStore from "../store/CustomerDataStore";
export default async function UpdateCardService(cardNumber: string, cardDate: string) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // create params
    const params = {
        "module": "account",
        "process": "updateCard",
        "accessToken": accessToken,
        "cardNumber": cardNumber,
        "cardDate": cardDate
    };

    //request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    if (!result) return;

    customerDataStore.updateCardData(cardNumber, cardDate);
    toast("Данные успешно обновлены", { type: "success" });
    return result;
}
import { styled } from '@mui/material';
import { useState } from 'react';
import MenuIcon from '../../../core/assets/module/drawer/menu.svg';
import Logotype from '../../../core/assets/module/shared/logotype.svg';
import LinkWrapperComponent from '../../shared/ui/LinkWrapperComponent';
import RetirementComponent from './RetirementComponent';
import TextComponent from './TextComponent';
import SharedMenuBottomSheetComponent from './SharedMenuBottomSheetComponent';
import TemplateConfig from '../../../core/config/TemplateConfig';
import NotifyIcon from "../../../core/assets/module/shared/notify.svg";
import NotifyCleanIcon from "../../../core/assets/module/shared/notify-clean.svg";
import NotifyComponent from '../../notify/ui/NotifyComponent';
import notifyStore from '../../notify/store/NotifyStore';

const SharedHeaderComponent = ({ title }: { title: string }) => {
    // menu
    const [menuState, setMenuState] = useState(false);

    // notify
    const [notifyState, setNotifyState] = useState(false);
    const isOlderThanThreeDays = (createdAt: string): boolean => {
        const createdDate = new Date(createdAt);
        const currentDate = new Date();
        const differenceInMillis = currentDate.getTime() - createdDate.getTime();
        const differenceInDays = differenceInMillis / (1000 * 60 * 60 * 24);
        return differenceInDays > 3;
    };

    // style
    const HeaderWrapper = styled('div')({
        display: 'grid',
        gridTemplateColumns: '90px 1fr 90px',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        width: TemplateConfig.isMobile ? 'calc(100% - 48px)' : TemplateConfig.mobileMaxWidth - 48,
        top: TemplateConfig.isMobile ? 20 : undefined,
        backgroundColor: TemplateConfig.whiteColor,
        paddingBottom: 20,
        zIndex: 2
    });

    return (
        <div>
            <HeaderWrapper>
                <LinkWrapperComponent onClick={() => setMenuState(true)}>
                    <img width={24} src={MenuIcon} alt="menu icon" />
                </LinkWrapperComponent>
                <div style={{ textAlign: 'center' }}>
                    <TextComponent type='large' weight={TemplateConfig.heavyText}>
                        {title}
                    </TextComponent>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <LinkWrapperComponent onClick={() => setNotifyState(true)}>
                        <img width={24} src={!notifyStore.data || notifyStore.data.notify.length === 0 || isOlderThanThreeDays(notifyStore.data.notify[0].created_at) ? NotifyCleanIcon : NotifyIcon} alt="notify" />
                    </LinkWrapperComponent>
                    <RetirementComponent size={10} />
                    <img width={24} src={Logotype} alt="lototype" />
                </div>
            </HeaderWrapper>
            <RetirementComponent size={50} />
            <SharedMenuBottomSheetComponent open={menuState} onDismiss={() => setMenuState(false)} />
            <NotifyComponent open={notifyState} onDismiss={() => setNotifyState(false)} />
        </div>
    );
}

export default SharedHeaderComponent;
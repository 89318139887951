import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import { ShortPartnerDataResponse } from "../../partner/model/ShortPartnerDataResponse";
import GlobalDataService from "../../partner/service/GlobalDataService";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function PurchaseService({ kitName }: { kitName: string; }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "product",
        "process": "purchase",
        "accessToken": accessToken,
        "kitName": kitName
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    // check result
    if (!result || !result.data) return;

    // open link
    toast("Покупка прошла успешно", { type: "success" });

    // update rating store
    await GlobalDataService();

    // set partner store
    partnerDataStore.replaceShortData(result.data as ShortPartnerDataResponse);
}
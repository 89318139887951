import { List, ListItem, ListItemIcon, ListItemSecondaryAction } from "@mui/material";
import RetirementComponent from "./RetirementComponent";
import TextComponent from "./TextComponent";

const SharedListComponent = ({ items, action = false }: { items: { label: string, icon: any, onClick: any, action?: any }[]; action?: boolean }) => {
    return (
        <List style={{ paddingTop: 0 }}>
            {
                items.map((field: any, index: number) => (
                    <ListItem key={index} button style={{ paddingLeft: 0 }} onClick={() => field.onClick()}>
                        <ListItemIcon style={{ minWidth: 24 }}>
                            <img src={field.icon} alt="shared list item icon" />
                        </ListItemIcon>
                        <RetirementComponent size={16} />
                        <div style={{ maxWidth: 380 }}>
                            <TextComponent type='boldMedium'>
                                {field.label}
                            </TextComponent>
                        </div>
                        {
                            action
                                ? <ListItemSecondaryAction style={{ position: 'absolute', right: 0 }}>
                                    {field.action}
                                </ListItemSecondaryAction>
                                : null
                        }
                    </ListItem>
                ))
            }
        </List>
    );
}

export default SharedListComponent;
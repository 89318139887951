export default function isCardExpired(cardDate: string): boolean {
    const [month, year] = cardDate.split(' / ').map(Number); // Разделяем строку и конвертируем в числа

    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Месяцы в JavaScript считаются с 0, поэтому добавляем 1
    const currentYear = currentDate.getFullYear() % 100; // Получаем последние две цифры текущего года

    // Проверяем, истек ли срок
    if (year < currentYear) {
        return true;
    } else if (year === currentYear && month <= currentMonth) {
        return true;
    }

    return false;
}
import LinkWrapperComponent from "./LinkWrapperComponent";
import TextComponent from "./TextComponent";

const TextButtonComponent = ({ placeholder, onClick, type = "default", color, weight }: { placeholder: string; onClick: any; type?: string; color?: string; weight?: number }) => {
    return (
        <TextComponent type={type} color={color} weight={weight}>
            <LinkWrapperComponent onClick={onClick}>
                <span style={{ textDecoration: 'underline', textDecorationThickness: 0.1, textUnderlineOffset: 2 }}>{placeholder}</span>
            </LinkWrapperComponent>
        </TextComponent>
    )
}

export default TextButtonComponent;
import { action, makeObservable, observable } from 'mobx';
import { CustomersResponse } from '../model/CustomersResponse';

class CustomersStore {
    data: CustomersResponse | null = null;

    constructor() {
        makeObservable(this, {
            data: observable,
            saveData: action,
            removeData: action,
        });
    }

    saveData(value: CustomersResponse) {
        this.data = value;
    }

    removeData() {
        this.data = null;
    }
}

const adminCustomersStore = new CustomersStore();
export default adminCustomersStore;

import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { Customer } from "../model/CustomerListResponse";
import adminCustomerListStore from "../store/CustomerListStore";

export default async function CustomerUpdateService({ customer, forename, surname, email, index }: { customer: Customer; forename: string; surname: string; email: string; index: number }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "customerUpdate",
        "accessToken": accessToken,
        "pointCustomerId": customer.id,
        "forename": forename,
        "surname": surname,
        "email": email
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );

    // check result
    if (!result || !result.data) return;

    // update store
    adminCustomerListStore.update(forename, surname, email, index, customer.confirm, customer.blocked);
    toast("Данные успешно обновлен", { type: "success" });
}
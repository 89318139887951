import kitsDataStore from "../../module/market/store/KitsDataStore";

export default function PackageNameUtil(personal_amount: string) {
    if (!kitsDataStore.kitsData) return "Не найдено";

    const personalAmount = Number(personal_amount);
    const kits = kitsDataStore.kitsData.kits.filter(item => item.bonus);

    let foundRankIndex = 0;
    for (let index = 0; index < kits.length; index++) {
        const item = kits[index];
        if (Number(item.sv) > personalAmount) break;
        foundRankIndex = index;
    }

    return kits[foundRankIndex].name;
}

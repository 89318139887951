import { useState } from "react";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import adminCustomerListStore from "../store/CustomerListStore";
import customersStore from "../store/CustomersStore";
import CustomersComponent from "./CustomersComponent";
import TeamIcon from "../../../core/assets/module/card/team.svg";
import TreeIcon from "../../../core/assets/module/card/tree.svg";
import RetirementComponent from "../../shared/ui/RetirementComponent";

const UsersComponent = () => {
    // init
    const users = customersStore.data?.customers;

    // customers
    const [customers, setCustomsers] = useState(false);

    return (
        <>
            <SharedCardItemComponent title="Инфо" icon={TeamIcon}>
                {
                    [
                        {
                            title: "Пользователи", value: [{
                                text: users?.allCustomers ?? '0',
                                onClick: () => {
                                    adminCustomerListStore.reset();
                                    adminCustomerListStore.setStatus('allCustomers');
                                    setCustomsers(true);
                                }
                            }]
                        },
                        {
                            title: "Партнеры", value: [{
                                text: users?.allPartners ?? '0',
                                onClick: () => {
                                    adminCustomerListStore.reset();
                                    adminCustomerListStore.setStatus('allPartners');
                                    setCustomsers(true);
                                }
                            }]
                        },
                        {
                            title: "Заблокированные", value: [{
                                text: users?.blockedCustomers ?? '0',
                                onClick: () => {
                                    adminCustomerListStore.reset();
                                    adminCustomerListStore.setStatus('blockedCustomers');
                                    setCustomsers(true);
                                }
                            }]
                        },
                    ]
                }
            </SharedCardItemComponent>
            <RetirementComponent size={10} />
            <SharedCardItemComponent title="Программы" icon={TreeIcon}>
                {
                    [
                        {
                            title: "Retail", value: [{
                                text: users?.otherPartners ?? '0',
                                onClick: () => {
                                    adminCustomerListStore.reset();
                                    adminCustomerListStore.setStatus('allPartners');
                                    setCustomsers(true);
                                }
                            }]
                        },
                        {
                            title: "Referral", value: [{
                                text: users?.otherPartners ?? '0',
                                onClick: () => {
                                    adminCustomerListStore.reset();
                                    adminCustomerListStore.setStatus('allPartners');
                                    setCustomsers(true);
                                }
                            }]
                        },
                        // {
                        //     title: "Rating", value: [{
                        //         text: users?.otherPartners ?? '0',
                        //         onClick: () => {
                        //             adminCustomerListStore.reset();
                        //             adminCustomerListStore.setStatus('allPartners');
                        //             setCustomsers(true);
                        //         }
                        //     }]
                        // },
                        {
                            title: "Residual", value: [{
                                text: users?.fundPartners ?? '0',
                                onClick: () => {
                                    adminCustomerListStore.reset();
                                    adminCustomerListStore.setStatus('fundPartners');
                                    setCustomsers(true);
                                }
                            }]
                        }
                    ]
                }
            </SharedCardItemComponent>
            <CustomersComponent open={customers} onDismiss={() => setCustomsers(false)} />
        </>
    );
}

export default UsersComponent;
import { format } from "date-fns";
import { ru } from 'date-fns/locale';
import { useEffect, useState } from "react";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import { SharedCardListItemModel } from "../../shared/model/SharedCardListItemModel";
import SharedCardListItemComponent from "../../shared/ui/SharedCardListItemComponent";
import adminProceduresStore from "../store/ProceduresStore";
import GetProceduresService from "../service/GetProceduresService";
import { observer } from "mobx-react-lite";
import SearchComponent from "./SearchComponent";

const ProceduresComponent = ({ open, onDismiss }: { open: boolean, onDismiss: any }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        async function fetch() {
            if (!adminProceduresStore.data && open && adminProceduresStore.loadAviable) {
                await GetProceduresService();
                setReload(!reload);
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [open]);

    // items 
    const Items = () => {
        if (!adminProceduresStore.data) return null;

        const componentItems: SharedCardListItemModel[] = [];
        adminProceduresStore.data!.procedures.map((field) => (
            componentItems.push({
                description: format(Date.parse(field.created_at), 'dd MMMM yyyy, HH:mm', { locale: ru }),
                title: PriceFormatUtil(Number(field.amount), true),
                value: field.text
            })
        ))

        return (
            <SharedCardListItemComponent>
                {componentItems}
            </SharedCardListItemComponent>
        );
    }

    // load more
    async function loadMore() {
        adminProceduresStore.incrementCurrentPage();
        await GetProceduresService();
        setReload(!reload);
    }

    // search
    const [search, setSearch] = useState(false);
    async function execSearch(number: string) {
        setSearch(false);
        adminProceduresStore.remove();
        adminProceduresStore.resetCurrentPage();
        adminProceduresStore.updateLoadAviable(true);
        adminProceduresStore.setOwnerId(number);
        await GetProceduresService();
        setReload(!reload);
    }

    // widget
    return (
        <>
            <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Список фин. операций' onSubmit={() => setSearch(true)} search>
                <Items />
                {
                    adminProceduresStore.loadAviable
                        ? <div>
                            <RetirementComponent />
                            <RaisedButtonComponent
                                onClick={loadMore}
                                placeholder="Показать еще"
                            />
                        </div>
                        : null
                }
            </StandartBottomSheetComponent>
            <SearchComponent open={search} onDismiss={() => setSearch(false)} onSubmit={execSearch} />
        </>
    );
}

export default observer(ProceduresComponent);
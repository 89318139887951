import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { Customer } from "../model/CustomerListResponse";
import TickIcon from "../../../core/assets/module/card-list/tick.svg";
import KeyIcon from "../../../core/assets/module/admin/key.svg";
import EditIcon from "../../../core/assets/module/admin/edit.svg";
import { useState } from "react";
import SharedListComponent from "../../shared/ui/SharedListComponent";
import ConfirmBottomSheetComponent from "../../shared/ui/ConfirmModalComponent";
import DataComponent from "./DataComponent";
import CustomerStatusService from "../service/CustomerStatusService";

const EditComponent = ({ open, onDismiss, customer, index }: { open: boolean; onDismiss: any; customer: Customer; index: number }) => {
    // init
    const [confirmText, setConfirmText] = useState('');
    const [dataWindow, setDataWindow] = useState(false);
    const [action, setAction] = useState('');
    if (!customer) return null;

    // items
    const actions = [
        {
            label: "Редактировать данные",
            onClick: () => {
                setDataWindow(true);
            },
            icon: EditIcon
        }
    ];
    if (Number(customer.confirm) === 0) actions.push({
        label: "Подтвердить аккаунт",
        onClick: () => {
            setAction('verify');
            setConfirmText('Вы уверены, что хотите подтвердить аккаунт?');
        },
        icon: TickIcon
    });
    if (Number(customer.blocked) === 0) actions.push({
        label: "Заблокировать аккаунт",
        onClick: () => {
            setAction('block');
            setConfirmText('Вы уверены, что хотите заблокировать аккаунт?');
        },
        icon: KeyIcon
    });
    else actions.push({
        label: "Разблокировать аккаунт",
        onClick: () => {
            setAction('unblock');
            setConfirmText('Вы уверены, что хотите разблокировать аккаунт?');
        },
        icon: KeyIcon
    });

    return (
        <>
            <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Действия'>
                <SharedListComponent items={actions} />
            </StandartBottomSheetComponent>
            <ConfirmBottomSheetComponent
                open={confirmText !== ''}
                onDismiss={() => setConfirmText('')}
                onSubmit={() => {
                    async function update() {
                        await CustomerStatusService({
                            customer: customer,
                            verify: action === 'verify' ? '1' : '0',
                            block: action === 'block' ? '1' : '0',
                            index: index
                        });
                        setConfirmText('');
                        onDismiss();
                    }

                    update();
                }}
                description={confirmText}
            />
            <DataComponent open={dataWindow} onDismiss={() => { setDataWindow(false); onDismiss(); }} customer={customer} index={index} />
        </>
    );
}

export default EditComponent;
import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function WalletService({ amount }: { amount: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return false;

    // request
    const params = {
        "module": "balance",
        "process": "wallet",
        "accessToken": accessToken,
        "amount": amount
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    if (!result || !result.data) return false;

    // save store
    partnerDataStore.reduceBalanceBonus(Number(amount));
    partnerDataStore.increaseBalance(Number(amount));
    toast("Операция прошла успешно", { type: "success" });
}
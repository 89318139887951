import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { CustomersResponse } from "../model/CustomersResponse";
import { VariablesRespnonse } from "../model/VariablesResponse";
import adminCustomersStore from "../store/CustomersStore";
import adminVariablesStore from "../store/VariablesStore";
export default async function GetDataService() {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "data",
        "accessToken": accessToken
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );

    // check result
    if (!result || !result.data) return;

    // add result to store
    adminVariablesStore.saveVariables(result.data as VariablesRespnonse);
    adminCustomersStore.saveData(result.data as CustomersResponse);
    return result.data;
}
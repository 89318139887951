import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import customerDataStore from "../store/CustomerDataStore";
import { useState } from "react";
import PasswordComponent from "./PasswordComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import { observer } from "mobx-react-lite";
import DataComponent from "./DataComponent";
import CabinetIcon from "../../../core/assets/module/card/cabinet.svg";
import ShieldIcon from "../../../core/assets/module/card/shield.svg";
import CardNumberIcon from "../../../core/assets/module/card/cardnumber.svg";
import CardDataComponent from "./CardDataComponent";

const ProfileComponent = () => {
    // data
    const [dataState, setDataState] = useState(false);

    // password
    const [passwordState, setPasswordState] = useState(false);

    // card
    const [cardState, setCardState] = useState(false);

    return (
        <>
            <SharedCardItemComponent
                buttonText="Редактировать данные"
                buttonAction={() => setDataState(true)}
                title="Мои данные"
                icon={CabinetIcon}
            >
                {
                    [
                        { title: "Имя", value: [{ text: customerDataStore.customerData!.customer.forename }] },
                        { title: "Фамилия", value: [{ text: customerDataStore.customerData!.customer.surname }] },
                        { title: "Адрес эл. почты", value: [{ text: customerDataStore.customerData!.customer.email }] },
                    ]
                }
            </SharedCardItemComponent>
            <RetirementComponent size={10} />
            <SharedCardItemComponent
                buttonText="Изменить пароль"
                buttonAction={() => setPasswordState(true)}
                title="Безопасность"
                icon={ShieldIcon}
            >
                {
                    [
                        { title: "Текущий пароль", value: [{ text: "******" }] },
                    ]
                }
            </SharedCardItemComponent>
            <RetirementComponent size={10} />
            <SharedCardItemComponent
                buttonText="Редактировать данные"
                buttonAction={() => setCardState(true)}
                title="Банковская карта"
                icon={CardNumberIcon}
            >
                {
                    [
                        { title: "Номер карты", value: [{ text: customerDataStore.customerData!.customer.card_number ?? "0000 0000 0000 0000" }] },
                        { title: "Срок действия", value: [{ text: customerDataStore.customerData!.customer.card_date ?? "00 / 00" }] }
                    ]
                }
            </SharedCardItemComponent>
            <PasswordComponent open={passwordState} onDismiss={() => setPasswordState(false)} />
            <DataComponent open={dataState} onDismiss={() => setDataState(false)} init={customerDataStore.customerData?.customer} />
            <CardDataComponent open={cardState} onDismiss={() => setCardState(false)} init={customerDataStore.customerData?.customer} />
        </>
    );
}
export default observer(ProfileComponent);
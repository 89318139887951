import { useEffect, useState } from "react";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
// import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import referralDataStore from "../store/ReferralDataStore";
import GetReferralsService from "../service/GetReferralsService";
import { observer } from "mobx-react-lite";
// import RankNameUtil from "../../../core/utils/RankNameUtil";
import { SharedCardListItemModel } from "../../shared/model/SharedCardListItemModel";
import SharedCardListItemComponent from "../../shared/ui/SharedCardListItemComponent";
import TextComponent from "../../shared/ui/TextComponent";
import TextButtonComponent from "../../shared/ui/TextButtonComponent";
import CopyTextUtil from "../../../core/utils/CopyTextUtil";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";

const ReferralsComponent = ({ open, onDismiss }: { open: boolean, onDismiss: any }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        async function fetch() { if (!referralDataStore.referralData && open && referralDataStore.referralLoadAviable) { await GetReferralsService({ page: 1 }); setReload(!reload); } }

        fetch();
        // eslint-disable-next-line
    }, [open]);

    // history 
    const History = () => {
        if (!referralDataStore.referralData) return null;

        const referralItems: SharedCardListItemModel[] = [];
        referralDataStore.referralData.referrals.map((field) => (
            referralItems.push({
                description: field.email,
                titleComponent: <div style={{ display: 'flex' }}>
                    <TextComponent type='boldMedium'>{field.forename} {field.surname}&nbsp;</TextComponent>
                    <TextButtonComponent
                        type='boldMedium'
                        placeholder={` (${ApplicationConfig.idValue}${field.number})`}
                        onClick={() => CopyTextUtil({ text: `${ApplicationConfig.idValue}${field.number}`, successText: "ID-номер скопирован" })}
                    />
                </div>,
                // value: `${PriceFormatUtil(Number(field.personal_amount), false, 'SV')} / ${RankNameUtil(field.left_total_amount, field.right_total_amount)}`
                value: `ОП: ${PriceFormatUtil(Number(field.personal_amount) * ApplicationConfig.exchange)}${field.fund_count.toString() !== '0' ? ` / Пакет “Founder”: ${field.fund_count}` : ''}${field.fund_count_2.toString() !== '0' ? ` / Пакет “Prime”: ${field.fund_count_2}` : ''}`
            })
        ))
        return (
            <SharedCardListItemComponent>
                {referralItems}
            </SharedCardListItemComponent>
        );
    }
    async function loadMore() {
        var newPage = referralDataStore.incrementCurrentPage();
        await GetReferralsService({ page: newPage });
        setReload(!reload);
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Список рефералов'>
            <History />
            {
                referralDataStore.referralLoadAviable
                    ? <div>
                        <RetirementComponent />
                        <RaisedButtonComponent
                            onClick={loadMore}
                            placeholder="Показать еще"
                        />
                    </div>
                    : null
            }
        </StandartBottomSheetComponent>
    );
}

export default observer(ReferralsComponent);
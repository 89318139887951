import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import notifyStore from "../../notify/store/NotifyStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { NotifyModel } from "../model/NotifyModel";

export default async function NotifyService(options: NotifyModel) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return true;

    // request
    const params = {
        "module": "admin",
        "process": "notify",
        "accessToken": accessToken,
        ...(options.title && { title: options.title }),
        ...(options.text && { text: options.text }),
        ...(options.notifyId && { notifyId: options.notifyId }),
        ...(options.delete && { delete: options.delete })
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    if (!result || !result.data) return false;

    if (!options.delete && !options.notifyId && options.title && options.text) {
        if (!result.data.id || !result.data.created_at) return false;
        notifyStore.add({
            id: result.data.id,
            title: options.title,
            text: options.text,
            created_at: result.data.created_at,
        });
        toast("Успешно добавлено", { type: "success" });
    }

    if (options.delete && options.notifyId) {
        notifyStore.delete(options.notifyId)
        toast("Успешно удалено", { type: "success" });
    }

    if (!options.delete && options.notifyId && options.title && options.text) {
        notifyStore.update(options.notifyId, options.title, options.text);
        toast("Успешно обновлено", { type: "success" });
    }
}
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import InputComponent from "../../shared/ui/InputComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import TextComponent from "../../shared/ui/TextComponent";
import GetIdDataService from "../service/GetIdDataService";
import TransferService from "../service/TransferService";

const TransferComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    // amount
    const amountFieldRef = useRef('');
    const onAmountFieldRefChange = (newValue: string) => { amountFieldRef.current = newValue };

    // number
    const numberFieldRef = useRef('');
    const onIdFieldRefChange = (newValue: string) => {
        numberFieldRef.current = newValue.substring(2);

        if (newValue.substring(2).length !== 6 && recipient !== '') setRecipient('');
        if (newValue.substring(2).length === 6) getName(newValue.substring(2));
    };
    const [recipient, setRecipient] = useState('');
    async function getName(id: string) {
        var result = await GetIdDataService({ recipientId: id });
        if (result) setRecipient(result);
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Внутренний перевод' onSubmit={() => {
            if (amountFieldRef.current.length < 1 || Number(amountFieldRef.current) < 1) {
                toast("Укажите сумму перевода", { type: "error" });
                return;
            }
            else if (numberFieldRef.current.length !== 6 || numberFieldRef.current === customerDataStore.customerData?.customer.number || recipient === '') {
                toast("Аккаунт не найден", { type: "error" });
                return;
            }
            else if (!partnerDataStore.partnerData || Number(amountFieldRef.current.replace(/\s/g, '')) > Number(partnerDataStore.partnerData.partner.balance)) {
                toast("Недостаточно средств на балансе", { type: "error" });
                return;
            }

            TransferService({ amount: Number(amountFieldRef.current.replace(/\s/g, '')), recipientNumber: numberFieldRef.current });
            onDismiss();
        }}>
            <InputComponent
                initText={numberFieldRef.current.length > 0 ? `${ApplicationConfig.idValue}${numberFieldRef.current}` : undefined}
                placeholder="Введите ID-номер получателя"
                handleTextFieldValueChange={onIdFieldRefChange}
                digitFilter
                numberFilter
                maxLength={8}
                endAdornment={<div style={{ width: 100, textAlign: 'right' }}><TextComponent multiline lines={1}>{recipient}</TextComponent></div>}
            />
            <RetirementComponent size={10} />
            <InputComponent
                initText={amountFieldRef.current}
                placeholder="Введите сумму (KZT)"
                handleTextFieldValueChange={onAmountFieldRefChange}
                filter={/[^0-9]/g}
                maxLength={11}
                priceFilter
            />
        </StandartBottomSheetComponent>
    );
}

export default TransferComponent;
import { useRef } from "react";
import { toast } from "react-toastify";
import InputComponent from "../../shared/ui/InputComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { Customer } from "../model/CustomerDataResponse";
import UpdateDataService from "../service/UpdateDataService";

const DataComponent = ({ open, onDismiss, init }: { open: boolean; onDismiss: any; init?: Customer }) => {
    const forenameField = useRef(init ? init.forename : '');
    const onForenameChange = (newValue: string) => { forenameField.current = newValue };
    const surnameField = useRef(init ? init.surname : '');
    const onSurnameChange = (newValue: string) => { surnameField.current = newValue };

    async function onUpdateData() {
        if (forenameField.current.length === 0 || surnameField.current.length === 0) {
            toast("Заполните все поля", { type: "error" });
            return;
        }
        await UpdateDataService({ forename: forenameField.current, surname: surnameField.current });
        onDismiss();
        onForenameChange('');
        onSurnameChange('');
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={onUpdateData} title="Редактирование данных">
            <InputComponent
                initText={forenameField.current}
                placeholder="Введите имя"
                handleTextFieldValueChange={onForenameChange}
                filter={/[^A-Za-zА-Яа-я]/g}
                maxLength={100}
            />
            <RetirementComponent size={10} />
            <InputComponent
                initText={surnameField.current}
                placeholder="Введите фамилию"
                handleTextFieldValueChange={onSurnameChange}
                filter={/[^A-Za-zА-Яа-я]/g}
                maxLength={100}
            />
        </StandartBottomSheetComponent>
    );
}

export default DataComponent;
import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { WithdrawalResponse } from "../model/WithdrawalResponse";
import adminWithdrawalStore from "../store/WithdrawalStore";
export default async function GetWithdrawalService({ page }: { page: number }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "withdrawal",
        "accessToken": accessToken,
        "page": page.toString()
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );

    // check result
    if (!result || !result.data) return;

    // save history
    if ((result.data as WithdrawalResponse).requests.length === 0) {
        adminWithdrawalStore.canLoadUpdate(false);
        if (page !== 1) toast("Все данные загружены", { type: "success" });
        return result.data;
    }
    adminWithdrawalStore.push((result.data as WithdrawalResponse).requests);
    return result.data;
}
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function GetLinkData({ referralLink }: { referralLink: string }) {
    //request
    const params = {
        "module": "partner",
        "process": "linkData",
        "referralLink": referralLink
    };
    var result = await GlobalRequestTemplate(
        {
            errorText: 'Спонсор не найден',
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );

    return result;
}
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import { KitsResponse } from "../../market/model/KitsResponse";
import kitsDataStore from "../../market/store/KitsDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { RanksResponse } from "../model/RanksResponse";
import { RatingResponse } from "../model/RatingResponse";
import rankDataStore from "../store/RankStore";
import ratingStore from "../store/RatingStore";

export default async function GlobalDataService() {
    // request
    const params = {
        "module": "globalData"
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );

    rankDataStore.saveData(result.data as RanksResponse);
    kitsDataStore.saveData(result.data as KitsResponse);
    ratingStore.save(result.data as RatingResponse);
}
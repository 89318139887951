import RetirementComponent from "./RetirementComponent";
import TextComponent from "./TextComponent";
import { SharedCardListItemModel } from "../model/SharedCardListItemModel";
import TemplateConfig from "../../../core/config/TemplateConfig";
import LinkWrapperComponent from "./LinkWrapperComponent";
import MoreIcon from "../../../core/assets/module/card-list/more.svg";

const SharedCardListItemComponent = ({ children, whitespace }: { children: SharedCardListItemModel[]; whitespace?: boolean }) => {
    if (children.length === 0) return null;
    return (
        <div>
            {
                children.map((field, index) =>
                    <div key={index}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div>
                                <TextComponent type='extraSmall'>{field.description}</TextComponent>
                                <RetirementComponent size={5} />
                                {
                                    field.title
                                        ? <TextComponent type='boldMedium'>{field.title}</TextComponent>
                                        : field.titleComponent ?? null
                                }
                                <RetirementComponent size={5} />
                                {
                                    field.value
                                        ? <TextComponent type='medium' whitespace={whitespace}>{field.value}</TextComponent>
                                        : field.valueComponent ?? null
                                }
                            </div>
                            {field.action && (<LinkWrapperComponent onClick={field.action}><img src={MoreIcon} alt="alt icon" /></LinkWrapperComponent>)}
                        </div>
                        <RetirementComponent size={10} />
                        <div style={{ width: '100%', borderBottom: `1px solid ${TemplateConfig.greyColor}` }} />
                        {index !== children.length - 1 && <RetirementComponent size={10} />}
                    </div>
                )
            }
        </div>
    );
}

export default SharedCardListItemComponent;
import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import partnerDataStore from "../store/PartnerDataStore";
export default async function PromoSaveService({ promoCode, promoCodeProcent }: { promoCode: string; promoCodeProcent: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "partner",
        "process": "promoCodeSave",
        "accessToken": accessToken,
        "promoCode": promoCode,
        "promoCodeProcent": promoCodeProcent
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    if (!result || !result.data) return;

    // save history
    partnerDataStore.replacePromoCode(promoCode, promoCodeProcent);
    toast("Успешно обновлено", { type: "success" });
    return result.data;
}
import BalanceIcon from "../../../core/assets/module/purchase/variant-balance.svg";
import QRIcon from "../../../core/assets/module/purchase/variant-qr.svg";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import SharedListComponent from "../../shared/ui/SharedListComponent";

const PurchaseComponent = ({ open, onDismiss, showCode, showPurchase, title }: { open: boolean; onDismiss: any; showCode: any; showPurchase: any; title: string }) => {
    // items
    const actions = [
        {
            label: "Списать с баланса",
            onClick: showPurchase,
            icon: BalanceIcon
        },
        {
            label: "Оплата через QR-код",
            onClick: showCode,
            icon: QRIcon
        }
    ];

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={title}>
            <SharedListComponent items={actions} />
        </StandartBottomSheetComponent>
    );
}

export default PurchaseComponent;
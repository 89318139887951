import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { AuthorizeResponse } from "../model/AuthorizeResponse";
import customerDataStore from "../store/CustomerDataStore";
import CustomerDataService from "./CustomerDataService";

export default async function AuthorizationService({ referralLink, navigate, email, forename, surname, password, isRegistration }: { referralLink: string; navigate: any; email: string; forename: string; surname: string; password: string; isRegistration: string }) {
    if (isRegistration === '1' && !referralLink) {
        toast("Регистрация без реферальной ссылки невозможна", { type: "error" });
        return false;
    }

    var resultCode = await sendRequest(referralLink, email, forename, surname, password, isRegistration);
    if (!resultCode) return false;

    if (!resultCode.status || !resultCode.data || resultCode.status !== 'succes') {
        toast("Произошла ошибка", { type: "error" });
        return false;
    }

    if (resultCode && isRegistration === '1') {
        toast("Регистрация прошла успешно", { type: "success" });
        return true;
    }
    var accessToken = (resultCode.data as AuthorizeResponse).access_token;
    localStorage.setItem('accessToken', accessToken);
    await CustomerDataService(accessToken);
    navigate(customerDataStore.customerData?.customer.admin_permission === '1' ? RouteNamespaces.profile : RouteNamespaces.home);
    return true;
}

async function sendRequest(referralLink: string, email: string, forename: string, surname: string, password: string, isRegistration: string) {
    const params = {
        "module": "account",
        "process": "auth",
        "referralLink": referralLink,
        "email": email,
        "forename": forename,
        "surname": surname,
        "password": password,
        "isRegistration": isRegistration
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );

    return result;
}
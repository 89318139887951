import { useRef } from "react";
import { toast } from "react-toastify";
import InputComponent from "../../shared/ui/InputComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import PasswordService from "../service/PasswordService";

const PasswordComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    const passwordField = useRef('');
    const onPasswordChange = (newValue: string) => { passwordField.current = newValue };
    const rePasswordField = useRef('');
    const onRePasswordChange = (newValue: string) => { rePasswordField.current = newValue };

    async function onUpdatePassword() {
        if (!passwordField.current || passwordField.current.length < 3) {
            toast("Заполните все поля", { type: "error" });
            return;
        }
        if (passwordField.current !== rePasswordField.current) {
            toast("Пароли не совпадают", { type: "error" });
            return;
        }
        await PasswordService(passwordField.current);
        onDismiss();
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={onUpdatePassword} title="Изменение пароля">
            <InputComponent
                initText={passwordField.current}
                placeholder="Введите новый пароль"
                handleTextFieldValueChange={onPasswordChange}
                password
            />
            <RetirementComponent size={10} />
            <InputComponent
                initText={rePasswordField.current}
                placeholder="Повторите новый пароль"
                handleTextFieldValueChange={onRePasswordChange}
                password
            />
        </StandartBottomSheetComponent>
    );
}

export default PasswordComponent;
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import partnerDataStore from "../store/PartnerDataStore";
import CopyTextUtil from "../../../core/utils/CopyTextUtil";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import { useState } from "react";
import ReferralsComponent from "./ReferralsComponent";
import { observer } from "mobx-react-lite";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import TreeIcon from "../../../core/assets/module/card/tree.svg";
// import RetirementComponent from "../../shared/ui/RetirementComponent";
import PromoCodeComponent from "./PromoCodeComponent";
import ActivationListComponent from "./ActivationListComponent";

const PartnerComponent = () => {
    // init
    const partner = partnerDataStore.partnerData!.partner;

    // team
    const [team, setTeam] = useState(false);

    // team
    const [promocode, setPromocode] = useState(false);

    // list
    const [listWindow, setListWindow] = useState(false);

    return (
        <>
            {
                <div>
                    {/* {
                        partner.personal_amount !== '0' && <div>
                            <SharedCardItemComponent
                                title="Программа “Retail”"
                                icon={TreeIcon}
                            // buttonText={`${partner.promo_code ? "Редактировать" : "Создать"} промокод`}
                            // buttonAction={() => setPromocode(true)}
                            >
                                {
                                    [
                                        { title: "Объём продаж (KZT)", value: [{ text: `${PriceFormatUtil(Number(partner.promo_code_amount), false, '')}` }] },
                                        // {
                                        //     title: "Промокод", value: [
                                        //         { text: "Скопировать", onClick: () => CopyTextUtil({ text: partner.promo_code!, successText: "Промокод скопирован" }) },
                                        //     ], hide: !partner.promo_code
                                        // },
                                        { title: "Аудитория", value: [{ text: "Показать ", onClick: () => setListWindow(true) }] },
                                    ]
                                }
                            </SharedCardItemComponent>
                            <RetirementComponent size={10} />
                        </div>
                    } */}
                    {
                        <SharedCardItemComponent title="Info" icon={TreeIcon}>
                            {
                                [
                                    { title: "Объём продаж (KZT)", value: [{ text: `${PriceFormatUtil(Number(partner.personal_amount) * ApplicationConfig.exchange, false, '')}` }] },
                                    {
                                        title: "Реферальная ссылка", value: [
                                            { text: "Скопировать", onClick: () => CopyTextUtil({ text: `${ApplicationConfig.applicationLink}/?referralLink=${partner.referral_link}`, successText: "Реф. ссылка скопирована" }) },
                                        ], hide: partner.personal_amount === '0'
                                    },
                                    { title: "Аудитория", value: [{ text: "Показать ", onClick: () => setTeam(true) }], hide: partner.personal_amount === '0' }
                                ]
                            }
                        </SharedCardItemComponent>
                    }
                </div>
            }
            <ReferralsComponent open={team} onDismiss={() => setTeam(false)} />
            <PromoCodeComponent open={promocode} onDismiss={() => setPromocode(false)} partner={partner} />
            <ActivationListComponent open={listWindow} onDismiss={() => setListWindow(false)} productName='' />
        </>
    );
}

export default observer(PartnerComponent);
import { useRef } from "react";
import { toast } from "react-toastify";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import InputComponent from "../../shared/ui/InputComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import WalletService from "../service/WalletService";

const WalletComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    // amount
    const amountFieldRef = useRef('');
    const onAmountFieldRefChange = (newValue: string) => { amountFieldRef.current = newValue };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Перевод между кошельками' onSubmit={() => {
            if (amountFieldRef.current.length < 1 || Number(amountFieldRef.current) < 1) {
                toast("Укажите сумму перевода", { type: "error" });
                return;
            }

            if (!partnerDataStore.partnerData || Number(amountFieldRef.current.replace(/\s/g, '')) > Number(partnerDataStore.partnerData.partner.balance_bonus)) {
                toast("Недостаточно средств на балансе", { type: "error" });
                return;
            }

            WalletService({ amount: amountFieldRef.current.replace(/\s/g, '') });
            onDismiss();
        }}>
            <InputComponent
                initText={amountFieldRef.current}
                placeholder="Введите сумму (KZT)"
                handleTextFieldValueChange={onAmountFieldRefChange}
                filter={/[^0-9]/g}
                maxLength={11}
                priceFilter
            />
        </StandartBottomSheetComponent>
    );
}

export default WalletComponent;
import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import AccessTokenVerifyService from "./AccessTokenVerifyService";
export default async function PasswordService(password: string) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // create params
    const params = {
        "module": "account",
        "process": "password",
        "accessToken": accessToken,
        "password": password
    };

    //request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    if (result.status && result.status === 'succes') toast("Пароль успешно изменен", { type: "success" });
    return result;
}
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { NotifyResponse } from "../model/NotifyResponse";
import notifyStore from "../store/NotifyStore";

export default async function NotifyDataService() {
    // request
    const params = {
        "module": "notifyData"
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    if (!result || !result.data) return false;
    notifyStore.save(result.data as NotifyResponse)
    return true;
}
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import InputComponent from "../../shared/ui/InputComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { Customer } from "../model/CustomerDataResponse";
import UpdateCardService from "../service/UpdateCardService";

const CardDataComponent = ({ open, onDismiss, init }: { open: boolean; onDismiss: any; init?: Customer }) => {
    const [reload, setReload] = useState(false);
    useEffect(() => {
        if (open && init) {
            numberChange(init.card_number ?? '');
            dateChange(init.card_date ?? '');
            setReload(!reload);
        } else {
            numberChange('');
            dateChange('');
        }
        // eslint-disable-next-line 
    }, [open, init]);

    const number = useRef('');
    const numberChange = (v: string) => { number.current = v };
    const date = useRef('');
    const dateChange = (v: string) => { date.current = v };

    async function onUpdateData() {
        if (number.current.length !== 19 || date.current.length !== 7) {
            toast("Заполните все поля", { type: "error" });
            return;
        }
        await UpdateCardService(number.current, date.current);
        onDismiss();
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={onUpdateData} title="Редактирование данных">
            <InputComponent
                initText={number.current}
                placeholder="Введите номер карточки"
                handleTextFieldValueChange={numberChange}
                creditCardFilter
                maxLength={19}
            />
            <RetirementComponent size={10} />
            <InputComponent
                initText={date.current}
                placeholder="Введите срок действия карточки"
                handleTextFieldValueChange={dateChange}
                creditDateFilter
                maxLength={7}
            />
        </StandartBottomSheetComponent>
    );
}

export default CardDataComponent;
export default class ApplicationConfig {
    // namespace
    static applicationName: string = 'UME';

    // link
    static baseLink: string = 'https://ume.kz';
    static applicationLink: string = 'https://id.ume.kz';
    static backendDirectory: string = `${ApplicationConfig.applicationLink}/request/api/v1/main.php`;
    static agreementLink = "https://info.zheke.site";

    // variable
    static idValue = 'Q-';

    // exchange
    static exchange = 500;
}
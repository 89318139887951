import { action, makeObservable, observable } from 'mobx';
import { Customer, CustomerListResponse } from '../model/CustomerListResponse';

class CustomerListStore {
    data: CustomerListResponse | null = null;
    currentPage: number = 1;
    loadAviable: boolean = true;
    ownerId: string = ''
    status: string = 'allCustomers' // allCustomers, blockedCustomers, allPartners, fundPartners

    constructor() {
        makeObservable(this, {
            data: observable,
            currentPage: observable,
            loadAviable: observable,
            ownerId: observable,
            status: observable,
            push: action,
            update: action,
            remove: action,
            incrementCurrentPage: action,
            resetCurrentPage: action,
            updateLoadAviable: action,
            setOwnerId: action,
            setStatus: action,
            reset: action
        });
    }

    incrementCurrentPage() { return this.currentPage = this.currentPage + 1; }

    resetCurrentPage() { return this.currentPage = 1; }

    setOwnerId(ownerId: string) { this.ownerId = ownerId; }

    setStatus(status: string) { this.status = status; }

    updateLoadAviable(value: boolean) { this.loadAviable = value; }

    push(customers: Customer[]) {
        if (!this.data) this.data = { customers: customers }
        else this.data.customers = [...this.data.customers, ...customers];
    }

    update(forename: string, surname: string, email: string, index: number, confirm: string, blocked: string) {
        if (!this.data) return;
        this.data.customers[index].forename = forename;
        this.data.customers[index].surname = surname;
        this.data.customers[index].email = email;
        this.data.customers[index].confirm = confirm;
        this.data.customers[index].blocked = blocked;
    }

    remove() { this.data = null; }

    reset() {
        this.remove();
        this.resetCurrentPage();
        this.updateLoadAviable(true);
        this.status = 'status';
        this.ownerId = '';
    }
}

const adminCustomerListStore = new CustomerListStore();
export default adminCustomerListStore;

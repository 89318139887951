import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import customerDataStore from "../store/CustomerDataStore";
import AccessTokenVerifyService from "./AccessTokenVerifyService";

export default async function UpdateDataService({ forename, surname }: { forename: string; surname: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    const params = {
        "module": "account",
        "process": "updateData",
        "accessToken": accessToken,
        "forename": forename,
        "surname": surname
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    if (!result) return;

    // store update
    customerDataStore.updateData(forename, surname);
    toast("Данные успешно обновлены", { type: "success" });
}
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function GetIdDataService({ recipientId }: { recipientId: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return false;

    // request
    const params = {
        "module": "balance",
        "process": "idData",
        "accessToken": accessToken,
        "recipientId": recipientId
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params,
            errorText: 'Аккаунт не найден'
        }
    );
    if (!result || !result.data) return false;
    // return forename, surname
    return `${result.data.recipient.forename} ${result.data.recipient.surname.substring(0, 1)}.`;
}
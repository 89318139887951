import { useEffect, useState } from "react";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import { observer } from "mobx-react-lite";
import { SharedCardListItemModel } from "../../shared/model/SharedCardListItemModel";
import SharedCardListItemComponent from "../../shared/ui/SharedCardListItemComponent";
import ActivationListService from "../service/ActivationListService";
import proActivationListStore from "../store/ProActivationListStore";
import premiumActivationListStore from "../store/PremiumActivationListStore";
import { format } from "date-fns";
import { ru } from 'date-fns/locale';
import sharedActivationListStore from "../store/SharedActivationListStore";

const ActivationListComponent = ({ open, onDismiss, productName }: { open: boolean, onDismiss: any; productName: string }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        async function fetch() {
            if (
                open
                &&
                (
                    (productName === 'pro' && !proActivationListStore.data && proActivationListStore.loadAviable)
                    ||
                    (productName === 'premium' && !premiumActivationListStore.data && premiumActivationListStore.loadAviable)
                    ||
                    (productName === '' && !sharedActivationListStore.data && sharedActivationListStore.loadAviable)
                )
            ) {
                await ActivationListService({ page: 1, productName: productName }); setReload(!reload);
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [open]);

    // Items 
    const Items = () => {
        if (
            (productName === 'pro' && !proActivationListStore.data)
            ||
            (productName === 'premium' && !premiumActivationListStore.data)
            ||
            (productName === '' && !sharedActivationListStore.data)
        ) return null;

        const listItems: SharedCardListItemModel[] = [];
        if (productName === 'pro') {
            proActivationListStore.data?.activations.map((field) => (
                listItems.push({
                    description: format(Date.parse(field.created_at), 'dd MMMM yyyy, HH:mm', { locale: ru }),
                    title: field.email,
                    value: "Тариф ‘Pro’"
                })
            ))
        }
        else if (productName === 'premium') {
            premiumActivationListStore.data?.activations.map((field) => (
                listItems.push({
                    description: format(Date.parse(field.created_at), 'dd MMMM yyyy, HH:mm', { locale: ru }),
                    title: field.email,
                    value: "Тариф ‘Premium’"
                })
            ))
        }
        else {
            sharedActivationListStore.data?.activations.map((field) => (
                listItems.push({
                    description: format(Date.parse(field.created_at), 'dd MMMM yyyy, HH:mm', { locale: ru }),
                    title: field.email,
                    value: `Тариф ${productName === 'pro' ? '‘Pro’' : '‘Premium’'}`
                })
            ))
        }
        return (
            <SharedCardListItemComponent>
                {listItems}
            </SharedCardListItemComponent>
        );
    }
    async function loadMore() {
        var newPage =
            productName === ''
                ? sharedActivationListStore.incrementCurrentPage()
                : productName === 'pro'
                    ? proActivationListStore.incrementCurrentPage()
                    : premiumActivationListStore.incrementCurrentPage();
        await ActivationListService({ page: newPage, productName: productName });
        setReload(!reload);
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Список актив. тарифов'>
            <Items />
            {
                (productName === 'pro' && proActivationListStore.loadAviable) || (productName === 'premium' && premiumActivationListStore.loadAviable) || (productName === '' && sharedActivationListStore.loadAviable)
                    ? <div>
                        <RetirementComponent />
                        <RaisedButtonComponent
                            onClick={loadMore}
                            placeholder="Показать еще"
                        />
                    </div>
                    : null
            }
        </StandartBottomSheetComponent>
    );
}

export default observer(ActivationListComponent);
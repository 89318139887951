import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import RouteNamespaces from './RouteNamespaces';
import { observer } from 'mobx-react-lite';
import customerDataStore from '../../module/authenticate/store/CustomerDataStore';
import PageNotFoundComponent from '../../module/shared/ui/PageNotFoundComponent';
import WrapperComponent from '../../module/shared/ui/WrapperComponent';
import AuthenticateComponent from '../../module/authenticate/ui/AuthenticateComponent';
import ProfileComponent from '../../module/authenticate/ui/ProfileComponent';
import PartnerComponent from '../../module/partner/ui/PartnerComponent';
import MarketComponent from '../../module/market/ui/MarketComponent';
import BalanceComponent from '../../module/balance/ui/BalanceComponent';
import BackgroundComponent from '../../module/shared/ui/BackgroundComponent';
import OrderComponent from '../../module/partner/ui/OrderComponent';
import HomeComponent from '../../module/partner/ui/HomeComponent';
import FinanceComponent from '../../module/admin/ui/FinanceComponent';
import UsersComponent from '../../module/admin/ui/UsersComponent';

function RouteComponent() {
    const PublicRoute = ({ children }: { children: any }) => {
        return !customerDataStore.customerData ? children : <Navigate to={RouteNamespaces.home} />;
    };

    const PrivateRoute = ({ children }: { children: any }) => {
        return customerDataStore.customerData ? children : <Navigate to={RouteNamespaces.authenticate} />;
    };

    const AdminRoute = ({ children }: { children: any }) => {
        return customerDataStore.customerData && customerDataStore.customerData.customer.admin_permission === '1' ? children : <Navigate to={RouteNamespaces.home} />;
    };

    const PartnerRoute = ({ children }: { children: any }) => {
        return customerDataStore.customerData && customerDataStore.customerData.customer.admin_permission === '0' ? children : <Navigate to={RouteNamespaces.profile} />;
    };

    return (
        <BrowserRouter>
            <Routes>
                <Route path={RouteNamespaces.error} element={<PageNotFoundComponent />} />

                <Route
                    path={RouteNamespaces.authenticate}
                    element={
                        <PublicRoute>
                            <WrapperComponent minimized={true} background>
                                <AuthenticateComponent />
                            </WrapperComponent>
                        </PublicRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.home}
                    element={
                        <PrivateRoute>
                            <PartnerRoute>
                                <WrapperComponent>
                                    <BackgroundComponent title={RouteNamespaces.homeName}>
                                        <HomeComponent />
                                    </BackgroundComponent>
                                </WrapperComponent>
                            </PartnerRoute>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.profile}
                    element={
                        <PrivateRoute>
                            <WrapperComponent>
                                <BackgroundComponent title={RouteNamespaces.profileName}>
                                    <ProfileComponent />
                                </BackgroundComponent>
                            </WrapperComponent>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.partner}
                    element={
                        <PrivateRoute>
                            <PartnerRoute>
                                <WrapperComponent>
                                    <BackgroundComponent title={RouteNamespaces.partnerName}>
                                        <PartnerComponent />
                                    </BackgroundComponent>
                                </WrapperComponent>
                            </PartnerRoute>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.market}
                    element={
                        <PrivateRoute>
                            <PartnerRoute>
                                <WrapperComponent>
                                    <BackgroundComponent title={RouteNamespaces.marketName}>
                                        <MarketComponent />
                                    </BackgroundComponent>
                                </WrapperComponent>
                            </PartnerRoute>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.order}
                    element={
                        <PrivateRoute>
                            <PartnerRoute>
                                <WrapperComponent>
                                    <BackgroundComponent title={RouteNamespaces.orderName}>
                                        <OrderComponent />
                                    </BackgroundComponent>
                                </WrapperComponent>
                            </PartnerRoute>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.balance}
                    element={
                        <PrivateRoute>
                            <PartnerRoute>
                                <WrapperComponent>
                                    <BackgroundComponent title={RouteNamespaces.balanceName}>
                                        <BalanceComponent />
                                    </BackgroundComponent>
                                </WrapperComponent>
                            </PartnerRoute>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.adminFinance}
                    element={
                        <PrivateRoute>
                            <AdminRoute>
                                <WrapperComponent>
                                    <BackgroundComponent title={RouteNamespaces.adminFinanceName}>
                                        <FinanceComponent />
                                    </BackgroundComponent>
                                </WrapperComponent>
                            </AdminRoute>
                        </PrivateRoute>
                    }
                />
                <Route
                    path={RouteNamespaces.adminUsers}
                    element={
                        <PrivateRoute>
                            <AdminRoute>
                                <WrapperComponent>
                                    <BackgroundComponent title={RouteNamespaces.adminUsersName}>
                                        <UsersComponent />
                                    </BackgroundComponent>
                                </WrapperComponent>
                            </AdminRoute>
                        </PrivateRoute>
                    }
                />
                <Route path={RouteNamespaces.another} element={<PageNotFoundComponent />} />
            </Routes>
        </BrowserRouter>
    );
}

export default observer(RouteComponent);
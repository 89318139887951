import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GetDataService from "../../admin/service/GetDataService";
import NotifyDataService from "../../notify/service/NotifyDataService";
import { PartnerDataResponse } from "../../partner/model/PartnerDataResponse";
import GlobalDataService from "../../partner/service/GlobalDataService";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { CustomerDataResponse } from "../model/CustomerDataResponse";
import customerDataStore from "../store/CustomerDataStore";
import LogoutService from "./LogoutService";

export default async function CustomerDataService(accessToken: string) {
    // create params
    const params = {
        "module": "account",
        "process": "data",
        "accessToken": accessToken
    };

    // request
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    if (!result || !result.status || result.status !== 'succes' || !result.data) {
        LogoutService(false);
        return;
    }

    // result
    customerDataStore.saveCustomer(result.data as CustomerDataResponse);
    partnerDataStore.savePartner(result.data as PartnerDataResponse);
    await GlobalDataService();
    await NotifyDataService();

    // admin load
    if (result.data.customer.admin_permission === '1') {
        await GetDataService();
    }
}
import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";

export default async function ResetPasswordService({ email }: { email: string }) {
    const params = {
        "module": "account",
        "process": "reset",
        "email": email
    };

    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    if (!result) return false;
    toast("Письмо отправлено на вашу эл. почту", { type: "success" });
    return true;
}
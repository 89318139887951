import { useRef } from "react";
import { toast } from "react-toastify";
import InputComponent from "../../shared/ui/InputComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { Customer } from "../model/CustomerListResponse";
import CustomerUpdateService from "../service/CustomerUpdateService";

const DataComponent = ({ open, onDismiss, customer, index }: { open: boolean; onDismiss: any; customer: Customer; index: number }) => {
    const forenameField = useRef(customer.forename);
    const onForenameChange = (newValue: string) => { forenameField.current = newValue };
    const surnameField = useRef(customer.surname);
    const onSurnameChange = (newValue: string) => { surnameField.current = newValue };
    const emailField = useRef(customer.email);
    const onEmailChange = (newValue: string) => { emailField.current = newValue };

    async function onUpdateData() {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailField.current || emailField.current.length < 3 || !emailRegex.test(emailField.current)) {
            toast("Некорректный адрес эл. почты", { type: "error" });
            return;
        }

        if (forenameField.current.length === 0 || surnameField.current.length === 0) {
            toast("Заполните все поля", { type: "error" });
            return;
        }
        await CustomerUpdateService({ forename: forenameField.current, surname: surnameField.current, email: emailField.current, index: index, customer: customer });
        onDismiss();
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} onSubmit={onUpdateData} title="Редактирование данных">
            <InputComponent
                initText={forenameField.current}
                placeholder="Введите имя"
                handleTextFieldValueChange={onForenameChange}
                filter={/[^A-Za-zА-Яа-я]/g}
                maxLength={100}
            />
            <RetirementComponent size={10} />
            <InputComponent
                initText={surnameField.current}
                placeholder="Введите фамилию"
                handleTextFieldValueChange={onSurnameChange}
                filter={/[^A-Za-zА-Яа-я]/g}
                maxLength={100}
            />
            <RetirementComponent size={10} />
            <InputComponent
                initText={emailField.current}
                placeholder="Введите адрес эл. почты"
                handleTextFieldValueChange={onEmailChange}
            />
        </StandartBottomSheetComponent>
    );
}

export default DataComponent;
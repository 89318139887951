import { action, makeObservable, observable } from 'mobx';
import { HistoryDataResponse, Procedure } from '../../balance/model/HistoryDataResponse';

class ProceduresStore {
    data: HistoryDataResponse | null = null;
    currentPage: number = 1;
    loadAviable: boolean = true;
    ownerId: string = ''

    constructor() {
        makeObservable(this, {
            data: observable,
            currentPage: observable,
            loadAviable: observable,
            ownerId: observable,
            push: action,
            remove: action,
            incrementCurrentPage: action,
            resetCurrentPage: action,
            updateLoadAviable: action,
            setOwnerId: action
        });
    }

    incrementCurrentPage() { return this.currentPage = this.currentPage + 1; }

    resetCurrentPage() { return this.currentPage = 1; }

    setOwnerId(ownerId: string) { this.ownerId = ownerId; }

    updateLoadAviable(value: boolean) { this.loadAviable = value; }

    push(procedures: Procedure[]) {
        if (!this.data) this.data = { procedures: procedures }
        else this.data.procedures = [...this.data.procedures, ...procedures];
    }

    remove() { this.data = null; }
}

const adminProceduresStore = new ProceduresStore();
export default adminProceduresStore;

import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import notifyStore from "../store/NotifyStore";
import { format } from "date-fns";
import { ru } from 'date-fns/locale';
import { SharedCardListItemModel } from "../../shared/model/SharedCardListItemModel";
import SharedCardListItemComponent from "../../shared/ui/SharedCardListItemComponent";
import TextComponent from "../../shared/ui/TextComponent";
import PlusIcon from "../../../core/assets/module/shared/plus.svg";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import AddNotifyComponent from "./AddNotifyComponent";
import { useState } from "react";
import EditIcon from "../../../core/assets/module/admin/edit.svg";
import DeleteIcon from "../../../core/assets/module/admin/delete.svg";
import MenuNotifyComponent from "./MenuNotifyComponent";
import NotifyService from "../../admin/service/NotifyService";
import ConfirmBottomSheetComponent from "../../shared/ui/ConfirmModalComponent";
import { NotifyItem } from "../model/NotifyResponse";
import { observer } from "mobx-react-lite";

const NotifyComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    // init
    const [reload, setReload] = useState(false);

    // list 
    const NotifyBlock = () => {
        if (!notifyStore.data) return null;

        const listItems: SharedCardListItemModel[] = [];
        notifyStore.data.notify.map((field) => (
            listItems.push({
                description: format(Date.parse(field.created_at), 'dd MMMM yyyy, HH:mm', { locale: ru }),
                titleComponent: <TextComponent type='boldMedium'>{field.title}</TextComponent>,
                value: field.text,
                action: customerDataStore.customerData?.customer.admin_permission === '1' ? () => {
                    setActive(field);
                    setMenuWindow(true);
                } : null
            })
        ))
        return (
            <SharedCardListItemComponent whitespace>
                {listItems}
            </SharedCardListItemComponent>
        );
    }

    // active item
    const [active, setActive] = useState<NotifyItem | undefined>(undefined);

    // windows
    const [menuWindow, setMenuWindow] = useState(false);
    const [addWindow, setAddWindow] = useState(false);
    const [deleteWindow, setDeleteWindow] = useState(false);

    // menu elements
    const elements = [
        {
            label: "Редактировать",
            onClick: () => {
                setMenuWindow(false);
                setAddWindow(true);
            },
            icon: EditIcon
        },
        {
            label: "Удалить",
            onClick: () => {
                setMenuWindow(false);
                setDeleteWindow(true);
            },
            icon: DeleteIcon
        }
    ];

    // delete
    async function execDelete() {
        setDeleteWindow(false);
        await NotifyService({ delete: '1', notifyId: active!.id });
        setReload(!reload);
    }

    // widget
    return (
        <>
            <StandartBottomSheetComponent
                open={open}
                onDismiss={onDismiss}
                title="Уведомление"
                submitIcon={PlusIcon}
                onSubmit={customerDataStore.customerData?.customer.admin_permission === '1' ? () => {
                    setActive(undefined);
                    setAddWindow(true);
                } : null}
            >
                <NotifyBlock />
            </StandartBottomSheetComponent>
            <AddNotifyComponent open={addWindow} onDismiss={() => setAddWindow(false)} init={active} />
            <MenuNotifyComponent open={menuWindow} onDismiss={() => setMenuWindow(false)} elements={elements} />
            <ConfirmBottomSheetComponent
                open={deleteWindow}
                onDismiss={() => setDeleteWindow(false)}
                onSubmit={execDelete}
                description="Вы уверены, что хотите удалить уведомление?"
            />
        </>
    );
}

export default observer(NotifyComponent);
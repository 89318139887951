import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import partnerDataStore from "../store/PartnerDataStore";
// import RankNameUtil from "../../../core/utils/RankNameUtil";
import { observer } from "mobx-react-lite";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import { format } from "date-fns";
import { ru } from 'date-fns/locale';
import CopyTextUtil from "../../../core/utils/CopyTextUtil";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import CabinetIcon from "../../../core/assets/module/card/cabinet.svg";
import StatIcon from "../../../core/assets/module/card/stat.svg";
import CarholderIcon from "../../../core/assets/module/card/cardholder.svg";
// import QualifyIcon from "../../../core/assets/module/card/qualify.svg";
import TreeIcon from "../../../core/assets/module/card/tree.svg";
import PackageNameUtil from "../../../core/utils/PackageNameUtil";
// import RatingIcon from "../../../core/assets/module/card/rating.svg";
// import ratingStore from "../store/RatingStore";
// import RatingComponent from "./RatingComponent";
// import { useState } from "react";

const HomeComponent = () => {
    // init
    const partner = partnerDataStore.partnerData!.partner;

    // rating
    // const [rating, setRating] = useState(false);

    return (
        <div>
            <SharedCardItemComponent title="Мои данные" icon={CabinetIcon}>
                {
                    [

                        { title: "Имя", value: [{ text: customerDataStore.customerData!.customer.forename }] },
                        { title: "Фамилия", value: [{ text: customerDataStore.customerData!.customer.surname }] },
                        { title: "Эл. почта", value: [{ text: customerDataStore.customerData!.customer.email }] },
                        { title: "ID-номер", value: [{ text: `${ApplicationConfig.idValue}${customerDataStore.customerData!.customer.number}`, onClick: () => CopyTextUtil({ text: `${ApplicationConfig.idValue}${customerDataStore.customerData!.customer.number}`, successText: "ID-номер скопирован" }) }] }
                    ]
                }
            </SharedCardItemComponent>
            <RetirementComponent size={10} />
            <SharedCardItemComponent title="Мой прогресс" icon={StatIcon}>
                {
                    [
                        { title: "Текущий статус", value: [{ text: partner.personal_amount === '0' ? 'No Status' : PackageNameUtil(partner.personal_amount) }] },
                        { title: "Объём продаж (KZT)", value: [{ text: `${PriceFormatUtil(Number(partner.personal_amount) * ApplicationConfig.exchange, false, '')}` }] },
                        { title: "Пакет “Founder”", value: [{ text: partner.fund_count }] },
                        { title: "Аудитория", value: [{ text: partner.invited_size }] }
                    ]
                }
            </SharedCardItemComponent>
            <RetirementComponent size={10} />
            <SharedCardItemComponent title="Мой куратор" icon={CabinetIcon}>
                {
                    [
                        { title: "Имя", value: [{ text: partner.referrer_forename ?? 'Нет' }] },
                        { title: "Фамилия", value: [{ text: partner.referrer_surname ?? 'Нет' }] },
                        { title: "Адрес эл. почты", value: [{ text: partner.referrer_email ?? 'Нет' }] },
                        {
                            title: "ID-номер", value: [{
                                text: partner.referrer_number ? `${ApplicationConfig.idValue}${partner.referrer_number}` : 'Нет',
                                onClick: () => partner.referrer_number ? CopyTextUtil({ text: `${ApplicationConfig.idValue}${partner.referrer_number}`, successText: "ID-номер скопирован" }) : undefined
                            }]
                        }
                    ]
                }
            </SharedCardItemComponent>
            <RetirementComponent size={10} />
            <SharedCardItemComponent title="Мои кошельки" icon={CarholderIcon}>
                {
                    [
                        { title: "Основной (KZT)", value: [{ text: PriceFormatUtil(Number(partner.balance), true, '') }] },
                        { title: "Бонусный (KZT)", value: [{ text: PriceFormatUtil(Number(partner.balance_bonus), true, '') }] },
                    ]
                }
            </SharedCardItemComponent>
            {/* <RetirementComponent size={10} />
            <SharedCardItemComponent title="Мой рейтинг" icon={RatingIcon}>
                {
                    [
                        {
                            title: "Рейтинг", value: [{
                                text:
                                    ratingStore.data!.rating_top.find(item => item.customer_id === customerDataStore.customerData!.customer.id)
                                        ? ratingStore.data!.rating_top.find(item => item.customer_id === customerDataStore.customerData!.customer.id)!.rating.toString()
                                        : "0"
                            }]
                        },
                        { title: "Общий ОП (KZT)", value: [{ text: PriceFormatUtil(Number(partner.rating_amount), false, '') }] },
                        {
                            title: "Рейтинг TOP-5", value: [{
                                text: "Показать",
                                onClick: () => setRating(true)
                            }]
                        },
                    ]
                }
            </SharedCardItemComponent> */}
            {/* <RetirementComponent size={10} />
            <SharedCardItemComponent title="Моя квалификация" icon={QualifyIcon}>
                {
                    [
                        { title: "Текущий ранг", value: [{ text: RankNameUtil(partner.left_total_amount, partner.right_total_amount) }] },
                        { title: "Следующий ранг", value: [{ text: RankNameUtil(partner.left_total_amount, partner.right_total_amount, true) }] },
                        { title: "Объём квалификации (QV)", value: [{ text: `${PriceFormatUtil(Number(partner.left_total_amount), false, '')}/ ${PriceFormatUtil(Number(partner.right_total_amount), false, '')}` }] }
                    ]
                }
            </SharedCardItemComponent> */}
            <RetirementComponent size={10} />
            <SharedCardItemComponent title="Мои программы" icon={TreeIcon}>
                {
                    [
                        { title: "Программа “Retail”", value: [{ text: `До ${partner.expired_at ? format(Date.parse(partner.expired_at), 'dd MMMM yyyy', { locale: ru }) : ''}` }], hide: partner.personal_amount === '0' },
                        { title: "Программа “Referral”", value: [{ text: `До ${partner.expired_at ? format(Date.parse(partner.expired_at), 'dd MMMM yyyy', { locale: ru }) : ''}` }], hide: partner.personal_amount === '0' },
                        // { title: "Программа “Rating”", value: [{ text: partner.personal_amount !== '0' ? `Да / до ${format(Date.parse(partner.expired_at), 'dd MMMM yyyy', { locale: ru })}` : 'Нет' }] },
                        { title: "Программа “Residual”", value: [{ text: `До ${partner.fund_expired_at ? format(Date.parse(partner.fund_expired_at), 'dd MMMM yyyy', { locale: ru }) : ''}` }], hide: partner.fund_permission === '0' || !partner.fund_expired_at }
                    ]
                }
            </SharedCardItemComponent>

            {/* <RatingComponent open={rating} onDismiss={() => setRating(false)} /> */}
        </div>
    );
}

export default observer(HomeComponent);
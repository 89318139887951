export default class RouteNamespaces {
    static authenticate: string = '/';

    static home: string = '/home';
    static homeName: string = 'Главная';

    static profile: string = '/profile';
    static profileName: string = 'Профиль';

    static market: string = '/market';
    static marketName: string = 'Маркет';

    static order: string = '/order';
    static orderName: string = 'Мои покупки';

    static partner: string = '/partner';
    static partnerName: string = 'Аудитория';

    static balance: string = '/balance';
    static balanceName: string = 'Кошельки';

    static adminFinance: string = '/admin/finance';
    static adminFinanceName: string = 'Финансы';

    static adminUsers: string = '/admin/users';
    static adminUsersName: string = 'Пользователи';

    static logoutName: string = 'Выход';

    static error: string = '/error';
    static another: string = '*';
}
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import { useState } from "react";
import HistoryComponent from "./HistoryComponent";
import TransferComponent from "./TransferComponent";
import { observer } from "mobx-react-lite";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import WithdrawalComponent from "./WithdrawalComponent";
import WalletComponent from "./WalletComponent";
import BonusIcon from "../../../core/assets/module/card/bonus.svg";
import CardIcon from "../../../core/assets/module/card/card.svg";

const BalanceComponent = () => {
    // init
    const partner = partnerDataStore.partnerData!.partner;

    // history
    const [kind, setKind] = useState('main');
    const [history, setHistory] = useState(false);

    // inner transfer
    const [transfer, setTransfer] = useState(false);

    // withdrawal
    const [withdrawal, setWithdrawal] = useState(false);

    // wallet transfer
    const [wallet, setWallet] = useState(false);

    return (
        <>
            <SharedCardItemComponent
                icon={CardIcon}
                title="Основной"
                buttonText="Перевод средств"
                buttonAction={() => setTransfer(true)}
            // textButtonText="Вывод средств"
            // textButtonAction={() => setWithdrawal(true)}
            >
                {
                    [
                        { title: "Доступный баланс (KZT)", value: [{ text: PriceFormatUtil(Number(partner.balance), true, '') }] },
                        { title: "Ожидают вывода (KZT)", value: [{ text: PriceFormatUtil(Number(partner.balance_locked), true, '') }] },
                        { title: "Финансовые операции", value: [{ text: "Показать", onClick: () => { setKind('main'); setHistory(true); } }] }
                    ]
                }
            </SharedCardItemComponent>
            <RetirementComponent size={10} />
            <SharedCardItemComponent
                icon={BonusIcon}
                title="Бонусный"
                buttonText="Перевод средств"
                buttonAction={() => setWallet(true)}>
                {
                    [
                        { title: "Доступный баланс (KZT)", value: [{ text: PriceFormatUtil(Number(partner.balance_bonus), true, '') }] },
                        { title: "Всего заработано (KZT)", value: [{ text: PriceFormatUtil(Number(partner.balance_history), true, '') }] },
                        { title: "Финансовые операции", value: [{ text: "Показать", onClick: () => { setKind('bonus'); setHistory(true); } }] }
                    ]
                }
            </SharedCardItemComponent>
            <HistoryComponent open={history} onDismiss={() => setHistory(false)} kind={kind} />
            <TransferComponent open={transfer} onDismiss={() => setTransfer(false)} />
            <WithdrawalComponent open={withdrawal} onDismiss={() => setWithdrawal(false)} />
            <WalletComponent open={wallet} onDismiss={() => setWallet(false)} />
        </>
    );
}

export default observer(BalanceComponent);
import { useNavigate } from "react-router-dom";
import LogoutService from "../../authenticate/service/LogoutService";
import RouteNamespaces from "../../../core/route/RouteNamespaces";
import ConfirmBottomSheetComponent from "../../shared/ui/ConfirmModalComponent";
import { useState } from "react";
import LogoutIcon from '../../../core/assets/module/drawer/logout.svg';
import ProfileIcon from '../../../core/assets/module/drawer/profile.svg';
import MarketIcon from '../../../core/assets/module/drawer/market.svg';
import PartnerIcon from '../../../core/assets/module/drawer/partner.svg';
import BalanceIcon from '../../../core/assets/module/drawer/balance.svg';
import OrderIcon from '../../../core/assets/module/drawer/order.svg';
import HomeIcon from '../../../core/assets/module/drawer/home.svg';
import BankIcon from '../../../core/assets/module/drawer/bank.svg';
import Background from '../../../core/assets/module/drawer/background.svg';
import WhiteLogotype from '../../../core/assets/module/shared/white-logotype.svg';
import SharedListComponent from "../../shared/ui/SharedListComponent";
import customerDataStore from "../../authenticate/store/CustomerDataStore";
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import RetirementComponent from "./RetirementComponent";
import TemplateConfig from "../../../core/config/TemplateConfig";
import TextComponent from "./TextComponent";
import LinkWrapperComponent from "./LinkWrapperComponent";

const SharedMenuBottomSheetComponent = ({ open, onDismiss }: { open: boolean; onDismiss: any }) => {
    const navigate = useNavigate();

    // logout
    const [logoutState, setLogoutState] = useState(false);
    function handleLogout() {
        setLogoutState(false);
        LogoutService();
        navigate(RouteNamespaces.authenticate);
    }
    let menuElements = [];
    if (customerDataStore.customerData && customerDataStore.customerData.customer.admin_permission === '0') menuElements.push({
        label: RouteNamespaces.homeName,
        onClick: () => { navigate(RouteNamespaces.home); onDismiss() },
        icon: HomeIcon
    });
    menuElements.push({
        label: RouteNamespaces.profileName,
        onClick: () => { navigate(RouteNamespaces.profile); onDismiss() },
        icon: ProfileIcon
    });
    if (customerDataStore.customerData && customerDataStore.customerData.customer.admin_permission === '0') menuElements.push(
        {
            label: RouteNamespaces.marketName,
            onClick: () => { navigate(RouteNamespaces.market); onDismiss() },
            icon: MarketIcon
        },
        {
            label: RouteNamespaces.orderName,
            onClick: () => { navigate(RouteNamespaces.order); onDismiss() },
            icon: OrderIcon
        },
        {
            label: RouteNamespaces.balanceName,
            onClick: () => { navigate(RouteNamespaces.balance); onDismiss() },
            icon: BalanceIcon
        },
        {
            label: RouteNamespaces.partnerName,
            onClick: () => { navigate(RouteNamespaces.partner); onDismiss() },
            icon: PartnerIcon
        },
    );
    if (customerDataStore.customerData && customerDataStore.customerData.customer.admin_permission === '1') menuElements.push(
        {
            label: RouteNamespaces.adminUsersName,
            onClick: () => { navigate(RouteNamespaces.adminUsers); onDismiss() },
            icon: PartnerIcon
        },
        {
            label: RouteNamespaces.adminFinanceName,
            onClick: () => { navigate(RouteNamespaces.adminFinance); onDismiss() },
            icon: BankIcon
        }
    );

    return (
        <div>
            <Drawer
                zIndex={2}
                open={open}
                onClose={onDismiss}
                direction='left'
                className="sharedDrawer"
            >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <div>
                        <div style={{ width: '100%', backgroundImage: `linear-gradient(135deg, ${TemplateConfig.blueColor}, ${TemplateConfig.pinkColor})` }}>
                            <div style={{ padding: TemplateConfig.extraPadding, display: 'grid', gridTemplateColumns: TemplateConfig.isMobile ? '1fr auto' : 'auto 1fr auto', alignItems: 'center', height: TemplateConfig.isMobile ? undefined : 152 }}>
                                {TemplateConfig.isMobile ? null : <img src={WhiteLogotype} width={80} alt="logotype" />}
                                <div style={{ paddingLeft: TemplateConfig.isMobile ? 0 : TemplateConfig.extraPadding, maxWidth: 200 }}>
                                    {
                                        TemplateConfig.isMobile
                                            ? <><img src={WhiteLogotype} alt="logotype" width={80} /></>
                                            : <>
                                                <TextComponent type="boldMedium" color={TemplateConfig.whiteColor}>
                                                    {customerDataStore.customerData?.customer.forename}
                                                </TextComponent>
                                                <RetirementComponent size={5} />
                                                <TextComponent type="boldMedium" color={TemplateConfig.whiteColor}>
                                                    {customerDataStore.customerData?.customer.surname}
                                                </TextComponent>
                                            </>
                                    }
                                    <RetirementComponent size={10} />
                                    {
                                        TemplateConfig.isMobile
                                            ? <>
                                                <TextComponent type="boldMedium" color={TemplateConfig.whiteColor}>
                                                    {customerDataStore.customerData?.customer.forename}
                                                </TextComponent>
                                                <RetirementComponent size={5} />
                                                <TextComponent type="boldMedium" color={TemplateConfig.whiteColor}>
                                                    {customerDataStore.customerData?.customer.surname}
                                                </TextComponent>
                                                <RetirementComponent size={5} />
                                            </>
                                            : null
                                    }
                                    <TextComponent type="boldMedium" color={TemplateConfig.whiteColor}>
                                        {customerDataStore.customerData?.customer.email}
                                    </TextComponent>
                                </div>
                                <div style={{ display: 'flex', height: '100%' }}>
                                    <LinkWrapperComponent onClick={() => setLogoutState(true)}><img src={LogoutIcon} alt="logout icon" /></LinkWrapperComponent>
                                </div>
                            </div>
                        </div>
                        <RetirementComponent size={40} />
                        <div style={{ paddingLeft: 40 }}>
                            <SharedListComponent items={menuElements} />
                        </div>
                    </div>
                    {window.innerHeight > 800 && <div style={{
                        height: '100%',
                        backgroundImage: `url(${Background})`,
                        margin: 40,
                        backgroundPositionY: 'bottom',
                        backgroundSize: 'contain',
                        WebkitBackgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                    }} />}
                </div>

            </Drawer>
            <ConfirmBottomSheetComponent
                open={logoutState}
                onDismiss={() => setLogoutState(false)}
                onSubmit={handleLogout}
                description="Вы уверены, что хотите выйти из аккаунта?"
            />
        </div>
    )
}

export default SharedMenuBottomSheetComponent;
import { useEffect, useState } from "react";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
// import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import { SharedCardListItemModel } from "../../shared/model/SharedCardListItemModel";
import SharedCardListItemComponent from "../../shared/ui/SharedCardListItemComponent";
import { observer } from "mobx-react-lite";
import SearchComponent from "./SearchComponent";
import adminCustomerListStore from "../store/CustomerListStore";
// import RankNameUtil from "../../../core/utils/RankNameUtil";
import TextComponent from "../../shared/ui/TextComponent";
import TextButtonComponent from "../../shared/ui/TextButtonComponent";
import CopyTextUtil from "../../../core/utils/CopyTextUtil";
import GetCustomersService from "../service/GetCustomersService";
import EditComponent from "./EditComponent";
import { Customer } from "../model/CustomerListResponse";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";

const CustomersComponent = ({ open, onDismiss }: { open: boolean, onDismiss: any }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        async function fetch() {
            if (!adminCustomerListStore.data && open && adminCustomerListStore.loadAviable) {
                await GetCustomersService();
                setReload(!reload);
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [open]);

    // items 
    const Items = () => {
        if (!adminCustomerListStore.data) return null;

        const componentItems: SharedCardListItemModel[] = [];
        adminCustomerListStore.data!.customers.map((field, index) => (
            componentItems.push({
                description: `${field.email}, ${Number(field.confirm) === 1 ? 'подтвержден' : 'не подтвержден'}`,
                titleComponent: <div style={{ display: 'flex' }}>
                    <TextComponent type='boldMedium'>{field.forename} {field.surname}&nbsp;</TextComponent>
                    <TextButtonComponent
                        type='boldMedium'
                        placeholder={` (${ApplicationConfig.idValue}${field.number})`}
                        onClick={() => CopyTextUtil({ text: `${ApplicationConfig.idValue}${field.number}`, successText: "ID-номер скопирован" })}
                    />
                </div>,
                // value: `${PriceFormatUtil(Number(field.personal_amount), false, 'SV')} / ${RankNameUtil(field.left_total_amount, field.right_total_amount)}${field.referrer_number.toString() === 'false' ? '' : ` / ${ApplicationConfig.idValue}${field.referrer_number}`}`,
                valueComponent: <div style={{ display: 'flex' }}>
                    <TextComponent type='medium'>
                        ОП: {PriceFormatUtil(Number(field.personal_amount) * ApplicationConfig.exchange)}
                        {
                            field.referrer_number.toString() !== 'false' && <>&nbsp;/&nbsp;</>
                        }
                        {field.referrer_number.toString() === 'false'
                            ? ''
                            // : <TextButtonComponent
                            //     type='medium'
                            //     placeholder={`${ApplicationConfig.idValue}${field.referrer_number}`}
                            //     onClick={() => CopyTextUtil({ text: `${ApplicationConfig.idValue}${field.referrer_number}`, successText: "ID-номер скопирован" })}
                            // />
                            : <span
                                style={{
                                    textDecoration: 'underline', textDecorationThickness: 0.1, textUnderlineOffset: 2
                                }}
                                onClick={() => CopyTextUtil({ text: `${ApplicationConfig.idValue}${field.referrer_number}`, successText: "ID-номер скопирован" })}
                            >
                                {ApplicationConfig.idValue}{field.referrer_number}
                            </span>
                        }
                        {
                            field.fund_count.toString() !== '0'
                                ? ` /  Пакет “Founder”: ${field.fund_count}`
                                : ''
                        }
                    </TextComponent>

                </div>,
                action: () => {
                    setActiveCustomer(field);
                    setActiveIndex(index);
                    setEdit(true);
                }
            })
        ))

        return (
            <SharedCardListItemComponent>
                {componentItems}
            </SharedCardListItemComponent>
        );
    }

    // load more
    async function loadMore() {
        adminCustomerListStore.incrementCurrentPage();
        await GetCustomersService();
        setReload(!reload);
    }

    // search
    const [search, setSearch] = useState(false);
    async function execSearch(number: string) {
        setSearch(false);
        adminCustomerListStore.remove();
        adminCustomerListStore.resetCurrentPage();
        adminCustomerListStore.updateLoadAviable(true);
        adminCustomerListStore.setOwnerId(number);
        await GetCustomersService();
        setReload(!reload);
    }

    // edit
    const [edit, setEdit] = useState(false);
    const [activeCustomers, setActiveCustomer] = useState<Customer | null>(null);
    const [activeIndex, setActiveIndex] = useState(0);

    // widget
    return (
        <>
            <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={
                adminCustomerListStore.status === 'allCustomers'
                    ? 'Список пользователей'
                    : adminCustomerListStore.status === 'blockedCustomers'
                        ? 'Список заблокированных'
                        : adminCustomerListStore.status === 'allPartners'
                            ? 'Список партнеров'
                            : adminCustomerListStore.status === 'fundPartners'
                                ? 'Список партнеров пр. “Residual”'
                                : 'Список партнеров пр. “Residual 2”'
            } onSubmit={() => setSearch(true)} search>
                <Items />
                {
                    adminCustomerListStore.loadAviable
                        ? <div>
                            <RetirementComponent />
                            <RaisedButtonComponent
                                onClick={loadMore}
                                placeholder="Показать еще"
                            />
                        </div>
                        : null
                }
            </StandartBottomSheetComponent>
            <SearchComponent open={search} onDismiss={() => setSearch(false)} onSubmit={execSearch} />
            <EditComponent open={edit} onDismiss={() => setEdit(false)} customer={activeCustomers!} index={activeIndex} />
        </>
    );
}

export default observer(CustomersComponent);
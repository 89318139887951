import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import InputComponent from "../../shared/ui/InputComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { Partner } from "../model/PartnerDataResponse";
import PromoSaveService from "../service/PromoSaveService";

const PromoCodeComponent = ({ open, onDismiss, partner }: { open: boolean; onDismiss: any; partner: Partner }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        if (open) {
            if (partner) {
                onNameChange(partner.promo_code ?? '');
                onProcentChange(partner.promo_code_procent ?? '');
            }
            else {
                onNameChange('');
                onProcentChange('');
            }

            setReload(!reload);
        }
        // eslint-disable-next-line
    }, [open, partner]);

    // name
    const name = useRef('');
    const onNameChange = (v: string) => { name.current = v };

    // procent
    const procent = useRef('');
    const onProcentChange = (v: string) => { procent.current = v };

    // function
    async function execSave() {
        if (name.current.length < 1) {
            toast("Задайте промокод", { type: "error" });
            return;
        }

        const personalAmount = Number(partner.personal_amount);
        const maxLimit = personalAmount >= 540
            ? 40
            : personalAmount >= 210
                ? 30
                : personalAmount >= 80
                    ? 20
                    : 0;
        if (Number(procent.current) < 1 || Number(procent.current) > maxLimit) {
            toast(`Возможное значение 1 - ${maxLimit}`, { type: "error" });
            return;
        }

        await PromoSaveService({ promoCode: name.current, promoCodeProcent: procent.current });
        onNameChange('');
        onProcentChange('');
        onDismiss();
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title={`${partner.promo_code ? "Редактировать" : "Создать"} промокод`} onSubmit={execSave}>
            <InputComponent
                initText={name.current}
                placeholder="Введите промокод"
                handleTextFieldValueChange={onNameChange}
                filter={/[^A-Za-zА-Яа-я0-9]/g}
                maxLength={50}
            />
            <RetirementComponent size={10} />
            <InputComponent
                initText={procent.current}
                placeholder="Введите % промокода"
                handleTextFieldValueChange={onProcentChange}
                filter={/[^0-9]/g}
                maxLength={2}
            />
        </StandartBottomSheetComponent>
    );
}

export default PromoCodeComponent;
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import NotifyService from "../../admin/service/NotifyService";
import InputComponent from "../../shared/ui/InputComponent";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import { NotifyItem } from "../model/NotifyResponse";

const AddNotifyComponent = ({ open, onDismiss, init }: { open: boolean; onDismiss: any; init?: NotifyItem }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        if (open) {
            if (init) {
                onTitleChange(init.title);
                onTextChange(init.text);
            } else {
                onTitleChange('');
                onTextChange('');
            }
            setReload(!reload);
        }
        // eslint-disable-next-line 
    }, [open, init]);

    // fields
    const title = useRef('');
    const onTitleChange = (v: string) => { title.current = v };
    const text = useRef('');
    const onTextChange = (v: string) => { text.current = v };

    // function
    async function execSave() {
        if (title.current.length < 2 || text.current.length < 2) {
            toast("Заполните поля", { type: "error" });
            return;
        }

        await NotifyService({
            text: text.current,
            title: title.current,
            ...(init && { notifyId: init.id }),
        });
        onTitleChange('');
        onTextChange('');
        onDismiss();
    }

    return (
        <StandartBottomSheetComponent
            open={open}
            onDismiss={onDismiss}
            title={`${init ? "Редактировать" : "Добавить"} уведомление`}
            onSubmit={execSave}
        >
            <InputComponent
                initText={title.current}
                placeholder="Заголовок"
                handleTextFieldValueChange={onTitleChange}
                maxLength={500}
            />
            <RetirementComponent />
            <InputComponent
                initText={text.current}
                placeholder="Текст"
                handleTextFieldValueChange={onTextChange}
                multiline
                rows="4"
                maxLength={2000}
            />
        </StandartBottomSheetComponent>
    );
}

export default AddNotifyComponent;
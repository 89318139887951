import SharedListComponent from "../../shared/ui/SharedListComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const MenuNotifyComponent = ({ open, onDismiss, elements }: { open: boolean; onDismiss: any; elements: any }) => {
    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Действия">
            <SharedListComponent items={elements} />
        </StandartBottomSheetComponent>
    );
}

export default MenuNotifyComponent;
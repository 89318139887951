import { useRef } from "react";
import { toast } from "react-toastify";
import InputComponent from "../../shared/ui/InputComponent";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";

const SearchComponent = ({ open, onDismiss, onSubmit }: { open: boolean; onDismiss: any; onSubmit: any }) => {
    // search
    const searchField = useRef('');
    const onChange = (v: string) => { searchField.current = v };

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Поиск" onSubmit={() => {
            if (searchField.current.length < 1) {
                toast("Аккаунт не найден", { type: "error" });
                return;
            }

            onSubmit(searchField.current);
        }}>
            <InputComponent
                initText={searchField.current}
                placeholder="Введите ID-номер, имя или фамилию"
                handleTextFieldValueChange={onChange}
            />
        </StandartBottomSheetComponent>
    );
}

export default SearchComponent;
export default function TelephoneFormatUtil(value: string) {
    const cleaned = value.replace(/\D/g, '');
    let formattedPhoneNumber = '';
    if (cleaned.length >= 1) {
        formattedPhoneNumber += `+${cleaned[0]}`;
    }
    if (cleaned.length > 1) {
        formattedPhoneNumber += ` (${cleaned.slice(1, 4)}`;
    }
    if (cleaned.length > 4) {
        formattedPhoneNumber += `) ${cleaned.slice(4, 7)}`;
    }
    if (cleaned.length > 7) {
        formattedPhoneNumber += ` ${cleaned.slice(7, 11)}`;
    }

    return formattedPhoneNumber;
}
import { action, makeObservable, observable } from 'mobx';
import { PartnerDataResponse } from '../model/PartnerDataResponse';
import { ShortPartnerDataResponse } from '../model/ShortPartnerDataResponse';

class PartnerDataStore {
    partnerData: PartnerDataResponse | null = null;

    constructor() {
        makeObservable(this, {
            partnerData: observable,
            savePartner: action,
            removePartner: action,
            increaseBalanceLocked: action,
            reduceBalance: action,
            reduceProProductCount: action,
            reducePremiumProductCount: action,
            replaceShortData: action,
            replacePromoCode: action
        });
    }

    savePartner(value: PartnerDataResponse) {
        this.partnerData = value;
    }

    increaseBalance(value: number) {
        if (!this.partnerData) return;
        this.partnerData.partner.balance = (Number(this.partnerData.partner.balance) + value).toString();
    }

    increaseBalanceLocked(value: number) {
        if (!this.partnerData) return;
        this.partnerData.partner.balance_locked = (Number(this.partnerData.partner.balance_locked) + value).toString();
    }

    increaseBalanceBonus(value: number) {
        if (!this.partnerData) return;
        this.partnerData.partner.balance_bonus = (Number(this.partnerData.partner.balance_bonus) + value).toString();
    }

    reduceBalance(value: number) {
        if (!this.partnerData) return;
        this.partnerData.partner.balance = (Number(this.partnerData.partner.balance) - value).toString();
    }

    reduceBalanceBonus(value: number) {
        if (!this.partnerData) return;
        this.partnerData.partner.balance_bonus = (Number(this.partnerData.partner.balance_bonus) - value).toString();
    }

    reduceProProductCount() {
        if (!this.partnerData) return;
        this.partnerData.partner.pro_product_count = (Number(this.partnerData.partner.pro_product_count) - 1).toString();
    }

    reducePremiumProductCount() {
        if (!this.partnerData) return;
        this.partnerData.partner.premium_product_count = (Number(this.partnerData.partner.premium_product_count) - 1).toString();
    }

    replaceShortData(value: ShortPartnerDataResponse) {
        if (!this.partnerData) return;
        this.partnerData.partner.balance = value.partner.balance;
        this.partnerData.partner.pro_product_count = value.partner.pro_product_count;
        this.partnerData.partner.premium_product_count = value.partner.premium_product_count;
        this.partnerData.partner.personal_amount = value.partner.personal_amount;
        this.partnerData.partner.referral_link = value.partner.referral_link;
        this.partnerData.partner.fund_permission = value.partner.fund_permission;
        this.partnerData.partner.fund_permission_2 = value.partner.fund_permission_2;
        this.partnerData.partner.expired_at = value.partner.expired_at;
        this.partnerData.partner.fund_expired_at = value.partner.fund_expired_at;
        this.partnerData.partner.rating_amount = value.partner.rating_amount;
        this.partnerData.partner.fund_expired_at_2 = value.partner.fund_expired_at_2;
        this.partnerData.partner.fund_count = value.partner.fund_count;
        this.partnerData.partner.fund_count_2 = value.partner.fund_count_2;
    }

    replacePromoCode(code: string, procent: string) {
        if (!this.partnerData) return;
        this.partnerData.partner.promo_code = code;
        this.partnerData.partner.promo_code_procent = procent;
    }

    removePartner() {
        this.partnerData = null;
    }
}

const partnerDataStore = new PartnerDataStore();
export default partnerDataStore;

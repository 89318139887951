import { action, makeObservable, observable } from 'mobx';
import { CustomerDataResponse } from '../model/CustomerDataResponse';

class CustomerDataStore {
    customerData: CustomerDataResponse | null = null;

    constructor() {
        makeObservable(this, {
            customerData: observable,
            saveCustomer: action,
            removeCustomer: action,
            updateData: action,
            updateCardData: action
        });
    }

    saveCustomer(value: CustomerDataResponse) {
        this.customerData = value;
    }

    updateData(forename: string, surname: string) {
        if (!this.customerData) return;
        this.customerData.customer.forename = forename;
        this.customerData.customer.surname = surname;
    }

    updateCardData(number: string, date: string) {
        if (!this.customerData) return;
        this.customerData.customer.card_number = number;
        this.customerData.customer.card_date = date;
    }

    removeCustomer() {
        this.customerData = null;
    }
}

const customerDataStore = new CustomerDataStore();
export default customerDataStore;

import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { HistoryDataResponse } from "../model/HistoryDataResponse";
import bonusHistoryDataStore from "../store/BonusHistoryDataStore";
import mainHistoryDataStore from "../store/MainHistoryDataStore";

export default async function GetHistoryService({ page, kind }: { page: number, kind: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "balance",
        "process": "data",
        "accessToken": accessToken,
        "kind": kind,
        "page": page.toString()
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    if (!result || !result.data) return;

    // save history
    if ((result.data as HistoryDataResponse).procedures.length === 0) {
        kind === 'main'
            ? mainHistoryDataStore.loadDisable(false)
            : bonusHistoryDataStore.loadDisable(false);
        if (page !== 1) toast("Все данные загружены", { type: "success" });
        return result.data;
    }
    kind === 'main'
        ? mainHistoryDataStore.pushHistory((result.data as HistoryDataResponse).procedures)
        : bonusHistoryDataStore.pushHistory((result.data as HistoryDataResponse).procedures);
    return result.data;
}
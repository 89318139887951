import customerDataStore from "../store/CustomerDataStore";
import AccessTokenVerifyService from "./AccessTokenVerifyService";
import CustomerDataService from "./CustomerDataService";
import LogoutService from "./LogoutService";

export default async function GlobalAuthVerifyService() {
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) {
        LogoutService(false);
        return;
    }
    if (customerDataStore.customerData) return;
    await CustomerDataService(accessToken);
}
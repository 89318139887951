import { Card } from "@mui/material";
import TemplateConfig from "../../../core/config/TemplateConfig";
import RetirementComponent from "./RetirementComponent";
import TextComponent from "./TextComponent";
import { SharedCardItemModel } from "../model/SharedCardItemModel";
import RaisedButtonComponent from "./RaisedButtonComponent";
import TextButtonComponent from "./TextButtonComponent";
import React from "react";
import LinkWrapperComponent from "./LinkWrapperComponent";

const SharedCardItemComponent = ({ children, buttonText, buttonAction, title, textButtonText, textButtonAction, icon }: { children: SharedCardItemModel[]; buttonText?: string; buttonAction?: any; title?: string, textButtonText?: string; textButtonAction?: any; icon?: string }) => {
    if (children.length === 0) return null;
    return (
        <Card
            variant="outlined"
            style={{ borderColor: TemplateConfig.greyColor, padding: TemplateConfig.extraPadding, borderRadius: 0 }}>
            {
                title
                    ? <div style={{ textAlign: 'center', borderBottom: `1px solid ${TemplateConfig.greyColor}` }}>
                        <TextComponent>{title}</TextComponent>
                        <RetirementComponent />
                    </div>
                    : null
            }
            <RetirementComponent />
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'start' }}>
                <div>
                    {
                        children.map((field, index) => (
                            !field.hide && <div key={index}>
                                <TextComponent weight={TemplateConfig.lightweightText}>{field.title}</TextComponent>
                                {field.description && <>
                                    <RetirementComponent size={5} />
                                    <TextComponent weight={TemplateConfig.lightweightText}>{field.description}</TextComponent>
                                </>}
                                <RetirementComponent size={5} />
                                <div style={{ display: 'flex', alignItems: 'center', maxWidth: TemplateConfig.isMobile ? 150 : 200 }}>
                                    {field.value.map((item, index) =>
                                        <React.Fragment key={index}>
                                            {
                                                item.onClick
                                                    ? <TextComponent type='boldMedium'>
                                                        <LinkWrapperComponent underline onClick={item.onClick}>{item.text}</LinkWrapperComponent>
                                                    </TextComponent>
                                                    : <TextComponent type='boldMedium'>{item.text}</TextComponent>
                                            }
                                            {index !== field.value.length - 1 && <TextComponent type='boldMedium'>&nbsp;/&nbsp;</TextComponent>}
                                        </React.Fragment>
                                    )}
                                </div>
                                {(index !== children.length - 1) && <RetirementComponent size={10} />}
                            </div>
                        ))
                    }
                </div>
                {
                    icon && <img src={icon} alt="icon" width={TemplateConfig.isMobile ? 54 : undefined} />
                }
            </div>
            {
                buttonText && buttonAction
                    ? <div>
                        <RetirementComponent />
                        <RaisedButtonComponent
                            placeholder={buttonText}
                            onClick={buttonAction}
                        />
                    </div>
                    : null
            }
            {
                textButtonText && textButtonAction
                    ? <div style={{ textAlign: 'center' }}>
                        <RetirementComponent />
                        <TextButtonComponent onClick={textButtonAction} placeholder={textButtonText} type='boldMedium' />
                    </div>
                    : null
            }
        </Card>
    );
}

export default SharedCardItemComponent;
import { action, makeObservable, observable } from 'mobx';
import { NotifyItem, NotifyResponse } from '../model/NotifyResponse';

class NotifyStore {
    data: NotifyResponse | null = null;

    constructor() {
        makeObservable(this, {
            data: observable,
            save: action,
            add: action,
            update: action,
            delete: action
        });
    }

    save(value: NotifyResponse) { this.data = value };
    add(value: NotifyItem) {
        if (!this.data) this.data = { notify: [value] }
        else this.data.notify = [value, ...this.data.notify];
    }
    update(id: string, title: string, text: string) {
        if (!this.data) return;
        const foundIndex = this.data.notify.findIndex(item => item.id === id);
        this.data.notify[foundIndex].title = title;
        this.data.notify[foundIndex].text = text;
    }
    delete(id: string) {
        if (!this.data) return;
        this.data.notify = [...this.data.notify.filter(item => item.id !== id)];
    }
}
const notifyStore = new NotifyStore();
export default notifyStore;

import adminCustomerListStore from "../../admin/store/CustomerListStore";
import adminCustomersStore from "../../admin/store/CustomersStore";
import adminProceduresStore from "../../admin/store/ProceduresStore";
import adminVariablesStore from "../../admin/store/VariablesStore";
import bonusHistoryDataStore from "../../balance/store/BonusHistoryDataStore";
import mainHistoryDataStore from "../../balance/store/MainHistoryDataStore";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import referralDataStore from "../../partner/store/ReferralDataStore";
import customerDataStore from "../store/CustomerDataStore";
import LogoutRequestService from "./LogoutRequestService";

export default function LogoutService(request: boolean = true) {
    if (request) LogoutRequestService();
    localStorage.removeItem("accessToken");

    // partner
    customerDataStore.removeCustomer();
    partnerDataStore.removePartner();
    mainHistoryDataStore.loadDisable(true);
    mainHistoryDataStore.resetCurrentPage();
    mainHistoryDataStore.removeHistory();
    bonusHistoryDataStore.loadDisable(true);
    bonusHistoryDataStore.resetCurrentPage();
    bonusHistoryDataStore.removeHistory();
    referralDataStore.loadDisable(true);
    referralDataStore.resetCurrentPage();
    referralDataStore.removeReferralData();

    // admin
    adminVariablesStore.removeVariables();
    adminCustomersStore.removeData();
    adminProceduresStore.updateLoadAviable(true);
    adminProceduresStore.resetCurrentPage();
    adminProceduresStore.setOwnerId('');
    adminProceduresStore.remove();
    adminCustomerListStore.reset();
}
import { action, makeObservable, observable } from 'mobx';
import { Activation, ActivationListResponse } from '../model/ActivationListResponse';

class ProActivationListStore {
    data: ActivationListResponse | null = null;
    currentPage: number = 1;
    loadAviable: boolean = true;

    constructor() {
        makeObservable(this, {
            data: observable,
            currentPage: observable,
            push: action,
            remove: action,
            incrementCurrentPage: action,
            resetCurrentPage: action,
            updateLoadAviable: action
        });
    }

    incrementCurrentPage() { return this.currentPage = this.currentPage + 1; }

    resetCurrentPage() { return this.currentPage = 1; }

    updateLoadAviable(value: boolean) { this.loadAviable = value; }

    push(activations: Activation[]) {
        if (!this.data) this.data = {
            activations: activations
        }
        else this.data.activations = [...this.data.activations, ...activations];
    }

    remove() { this.data = null; }
}

const proActivationListStore = new ProActivationListStore();
export default proActivationListStore;

import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { ActivationListResponse } from "../model/ActivationListResponse";
import premiumActivationListStore from "../store/PremiumActivationListStore";
import proActivationListStore from "../store/ProActivationListStore";
import sharedActivationListStore from "../store/SharedActivationListStore";
export default async function ActivationListService({ page, productName }: { page: number; productName: string }) {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "partner",
        "process": "activationList",
        "accessToken": accessToken,
        "page": page.toString(),
        "productName": productName
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );
    if (!result || !result.data) return;

    // save history
    if ((result.data as ActivationListResponse).activations.length === 0) {
        productName === '' ?
            sharedActivationListStore.updateLoadAviable(false)
            : productName === 'pro'
                ? proActivationListStore.updateLoadAviable(false)
                : premiumActivationListStore.updateLoadAviable(false);
        if (page !== 1) toast("Все данные загружены", { type: "success" });
        return result.data;
    }
    productName === '' ?
        sharedActivationListStore.push((result.data as ActivationListResponse).activations)
        : productName === 'pro'
            ? proActivationListStore.push((result.data as ActivationListResponse).activations)
            : premiumActivationListStore.push((result.data as ActivationListResponse).activations);
    return result.data;
}
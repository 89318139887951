import { action, makeObservable, observable } from 'mobx';
import { RequestItem, WithdrawalResponse } from '../model/WithdrawalResponse';

class WithdrawalStore {
    data: WithdrawalResponse | null = null;
    currentPage: number = 1;
    canLoad: boolean = true;

    constructor() {
        makeObservable(this, {
            data: observable,
            currentPage: observable,
            canLoad: observable,
            push: action,
            remove: action,
            removeItem: action,
            incrementCurrentPage: action,
            resetCurrentPage: action,
            canLoadUpdate: action
        });
    }

    push(requests: RequestItem[]) {
        if (!this.data) this.data = {
            requests: requests
        }
        else this.data.requests = [...this.data.requests, ...requests];
    }

    remove() {
        this.data = null;
    }

    removeItem(index: number) {
        if (!this.data) return;
        this.data.requests = this.data.requests.filter((item, ind) => ind !== index);
    }

    incrementCurrentPage() {
        return this.currentPage = this.currentPage + 1;
    }

    resetCurrentPage() {
        return this.currentPage = 1;
    }

    canLoadUpdate(value: boolean) {
        this.canLoad = value;
    }
}

const adminWithdrawalStore = new WithdrawalStore();
export default adminWithdrawalStore;

import { observer } from "mobx-react-lite";
import { useState } from "react";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import HistoryComponent from "../../balance/ui/HistoryComponent";
import TransferComponent from "../../balance/ui/TransferComponent";
import partnerDataStore from "../../partner/store/PartnerDataStore";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import SharedCardItemComponent from "../../shared/ui/SharedCardItemComponent";
import adminVariablesStore from "../store/VariablesStore";
import ProceduresComponent from "./ProceduresComponent";
import RequestsWithdrawalComponent from "./RequestsWithdrawalComponent";
import StatIcon from "../../../core/assets/module/card/stat.svg";
import CardholderIcon from "../../../core/assets/module/card/cardholder.svg";
import adminCustomersStore from "../store/CustomersStore";

const FinanceComponent = () => {
    // init
    const variables = adminVariablesStore.variables?.variables;
    const partner = partnerDataStore.partnerData!.partner;

    // inner transfer
    const [transfer, setTransfer] = useState(false);

    // history
    const [kind, setKind] = useState('main');
    const [history, setHistory] = useState(false);

    // withdrawal
    const [withdrawal, setWithdrawal] = useState(false);

    // withdrawal
    const [procedures, setProcedures] = useState(false);

    return (
        <>
            <SharedCardItemComponent title="Инфо" icon={StatIcon}>
                {
                    [
                        { title: "Доход компании (KZT)", value: [{ text: PriceFormatUtil(Number(variables?.profit_amount), true, '') }] },
                        { title: "Расход по бонусам (KZT)", value: [{ text: PriceFormatUtil(Number(variables?.expense_amount), true, '') }] },
                        { title: "Чистый прибыль (KZT)", value: [{ text: PriceFormatUtil(Number(variables?.profit_amount) - Number(variables?.expense_amount), true, '') }] },
                        { title: "Баланс партнеров (KZT)", value: [{ text: PriceFormatUtil(Number(adminCustomersStore.data?.customers.partnerBalance), true, '') ?? "0" }] },
                        { title: "Пакет “Founder”", value: [{ text: adminCustomersStore.data?.customers.fundCount ?? "0" }] },
                        { title: 'Фонд программы "Residual"', value: [{ text: PriceFormatUtil(Number(variables?.fund_amount), true, '') }] },
                        // { title: 'Фонд программы "Rating"', value: [{ text: PriceFormatUtil(Number(variables?.rating_fund_amount), true, '') }] },
                        { title: "Все фин. операции", value: [{ text: "Показать", onClick: () => setProcedures(true) }] },
                        { title: "Запросы на вывод средств", value: [{ text: "Показать", onClick: () => setWithdrawal(true) }] }
                    ]
                }
            </SharedCardItemComponent>
            <RetirementComponent size={10} />
            <SharedCardItemComponent
                icon={CardholderIcon}
                title="Кошелек"
                buttonText="Перевод средств"
                buttonAction={() => setTransfer(true)}>
                {
                    [
                        { title: "Доступный баланс (KZT)", value: [{ text: PriceFormatUtil(Number(partner.balance), true, '') }] },
                        { title: "Финансовые операции", value: [{ text: "Показать", onClick: () => { setKind('main'); setHistory(true); } }] }
                    ]
                }
            </SharedCardItemComponent>
            <TransferComponent open={transfer} onDismiss={() => setTransfer(false)} />
            <HistoryComponent open={history} onDismiss={() => setHistory(false)} kind={kind} />
            <RequestsWithdrawalComponent open={withdrawal} onDismiss={() => setWithdrawal(false)} />
            <ProceduresComponent open={procedures} onDismiss={() => setProcedures(false)} />
        </>
    );
}

export default observer(FinanceComponent);
export default function СreditCardFormatUtil(value: string) {
    const cleaned = value.replace(/\D/g, '');
    let formattedCreditCardNumber = '';
    for (let i = 0; i < cleaned.length; i++) {
        if (i > 0 && i % 4 === 0) {
            formattedCreditCardNumber += ' ';
        }
        formattedCreditCardNumber += cleaned[i];
    }
    return formattedCreditCardNumber;
}

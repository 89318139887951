import { observer } from "mobx-react-lite";
import TickIcon from "../../../core/assets/module/card-list/tick.svg";
import CloseIcon from "../../../core/assets/module/card-list/close.svg";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import SharedListComponent from "../../shared/ui/SharedListComponent";
import { useState } from "react";
import ConfirmBottomSheetComponent from "../../shared/ui/ConfirmModalComponent";
import SolveWithdrawalService from "../service/SolveWithdrawalService";

const SolveWithdrawalComponent = ({ open, onDismiss, withdrawalId, customerId, index }: { open: boolean; onDismiss: any; withdrawalId: string; customerId: string; index: number }) => {
    // items
    const actions = [
        {
            label: "Подтвердить запрос",
            onClick: () => setConfirm(true),
            icon: TickIcon
        },
        {
            label: "Отклонить запрос",
            onClick: () => setReject(true),
            icon: CloseIcon
        }
    ];

    // confirm
    const [confirm, setConfirm] = useState(false);

    // delete
    const [reject, setReject] = useState(false);

    // solve
    async function solve(result: string) {
        await SolveWithdrawalService({
            customerId: customerId,
            withdrawalId: withdrawalId,
            index: index,
            result: result
        });
        setConfirm(false);
        setReject(false);
        onDismiss();
    }

    return (
        <>
            <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title="Действия">
                <SharedListComponent items={actions} />
            </StandartBottomSheetComponent>
            <ConfirmBottomSheetComponent
                open={confirm}
                onDismiss={() => setConfirm(false)}
                onSubmit={() => solve('1')}
                description="Вы уверены, что хотите подтвердить запрос?"
            />
            <ConfirmBottomSheetComponent
                open={reject}
                onDismiss={() => setReject(false)}
                onSubmit={() => solve('0')}
                description="Вы уверены, что хотите отклонить запрос?"
            />
        </>
    );
}

export default observer(SolveWithdrawalComponent);
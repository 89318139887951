export default class TemplateConfig {
    // size
    static mobileMaxWidth: number = 520;
    static isMobile = window.innerWidth <= TemplateConfig.mobileMaxWidth;

    // padding
    static miniPadding: number = 8;
    static defaultPadding: number = 16;
    static extraPadding: number = 24;

    // font size
    static extraSmallFontSize: number = 12;
    static smallFontSize: number = 14;
    static mediumFontSize: number = 16;
    static largeFontSize: number = 20;
    static extraLargeFontSize: number = 24;

    // weight
    static lightweightText: number = 200;
    static regularText: number = 300;
    static mediumText: number = 400;
    static semiboldText: number = 500;
    static heavyText: number = 600;

    // color
    static whiteColor: string = '#ffffff';
    static blackColor: string = '#000000';
    static greyColor: string = '#DADCE0';
    static greyColorOpacity: string = 'rgba(218, 220, 224, 0.42)';
    static blueColor: string = '#00078F';
    static pinkColor: string = '#e725e1';
}
import { format } from "date-fns";
import { ru } from 'date-fns/locale';
import { useEffect, useState } from "react";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import GetHistoryService from "../service/GetHistoryService";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import mainHistoryDataStore from "../store/MainHistoryDataStore";
import bonusHistoryDataStore from "../store/BonusHistoryDataStore";
import { SharedCardListItemModel } from "../../shared/model/SharedCardListItemModel";
import SharedCardListItemComponent from "../../shared/ui/SharedCardListItemComponent";

const HistoryComponent = ({ open, onDismiss, kind }: { open: boolean, onDismiss: any; kind: string }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        async function fetch() {
            if ((kind === 'main' && !mainHistoryDataStore.historyData && open && mainHistoryDataStore.historyLoadAviable) || (kind === 'bonus' && !bonusHistoryDataStore.historyData && open && bonusHistoryDataStore.historyLoadAviable)) {
                await GetHistoryService({ page: 1, kind: kind });
                setReload(!reload);
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [open]);

    // history 
    const History = () => {
        if ((kind === 'main' && !mainHistoryDataStore.historyData) || (kind === 'bonus' && !bonusHistoryDataStore.historyData)) return null;

        const historyItems: SharedCardListItemModel[] = [];
        if (kind === 'main') {
            mainHistoryDataStore.historyData!.procedures.map((field) => (
                historyItems.push({
                    description: format(Date.parse(field.created_at), 'dd MMMM yyyy, HH:mm', { locale: ru }),
                    title: PriceFormatUtil(Number(field.amount), true),
                    value: field.text
                })
            ))
        } else {
            bonusHistoryDataStore.historyData!.procedures.map((field) => (
                historyItems.push({
                    description: format(Date.parse(field.created_at), 'dd MMMM yyyy, HH:mm', { locale: ru }),
                    title: PriceFormatUtil(Number(field.amount), true),
                    value: field.text
                })
            ))
        }

        return (
            <SharedCardListItemComponent>
                {historyItems}
            </SharedCardListItemComponent>
        );
    }
    async function loadMore() {
        var newPage = kind === 'main'
            ? mainHistoryDataStore.incrementCurrentPage()
            : bonusHistoryDataStore.incrementCurrentPage();
        await GetHistoryService({ page: newPage, kind: kind });
        setReload(!reload);
    }

    return (
        <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Список фин. операций'>
            <History />
            {
                (kind === 'main' && mainHistoryDataStore.historyLoadAviable)
                    || (kind === 'bonus' && bonusHistoryDataStore.historyLoadAviable)
                    ? <div>
                        <RetirementComponent />
                        <RaisedButtonComponent
                            onClick={loadMore}
                            placeholder="Показать еще"
                        />
                    </div>
                    : null
            }
        </StandartBottomSheetComponent>
    );
}

export default HistoryComponent;
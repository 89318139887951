import { format } from "date-fns";
import { ru } from 'date-fns/locale';
import { useEffect, useState } from "react";
import StandartBottomSheetComponent from "../../shared/ui/StandartBottomSheetComponent";
import PriceFormatUtil from "../../../core/utils/PriceFormatUtil";
import RetirementComponent from "../../shared/ui/RetirementComponent";
import RaisedButtonComponent from "../../shared/ui/RaisedButtonComponent";
import { SharedCardListItemModel } from "../../shared/model/SharedCardListItemModel";
import SharedCardListItemComponent from "../../shared/ui/SharedCardListItemComponent";
import adminWithdrawalStore from "../store/WithdrawalStore";
import GetWithdrawalService from "../service/GetWithdrawalService";
import TextComponent from "../../shared/ui/TextComponent";
import TextButtonComponent from "../../shared/ui/TextButtonComponent";
import CopyTextUtil from "../../../core/utils/CopyTextUtil";
import { observer } from "mobx-react-lite";
import SolveWithdrawalComponent from "./SolveWithdrawalComponent";
import isCardExpired from "../../../core/utils/CardExpiredUtil";

const RequestsWithdrawalComponent = ({ open, onDismiss }: { open: boolean, onDismiss: any }) => {
    // init
    const [reload, setReload] = useState(false);
    useEffect(() => {
        async function fetch() {
            if (!adminWithdrawalStore.data && open && adminWithdrawalStore.canLoad) {
                await GetWithdrawalService({ page: 1 });
                setReload(!reload);
            }
        }

        fetch();
        // eslint-disable-next-line
    }, [open]);

    // Items 
    const Items = () => {
        if (!adminWithdrawalStore.data) return null;

        const historyItems: SharedCardListItemModel[] = [];
        adminWithdrawalStore.data.requests.map((field, index) => (
            historyItems.push({
                description: format(Date.parse(field.created_at), 'dd MMMM yyyy, HH:mm', { locale: ru }),
                title: PriceFormatUtil(Number(field.amount), true),
                valueComponent: <div style={{ display: 'flex' }}>
                    <TextComponent type='medium'>Карта{!field.card_date ? ' (срок не указан)' : isCardExpired(field.card_date) ? ' (срок истек)' : ''}:&nbsp;</TextComponent>
                    <TextButtonComponent
                        placeholder={field.card_number ?? 'Не указан'}
                        onClick={() => CopyTextUtil({ text: field.card_number ?? '', successText: "Номер карты скопирован" })}
                        type='medium'
                    />
                </div>,
                action: () => {
                    setWithdrawalIndex(index);
                    setWithdrawalId(field.id);
                    setCustomerId(field.customer_id);
                    setSolve(true);
                }
            })
        ))

        return (
            <SharedCardListItemComponent>
                {historyItems}
            </SharedCardListItemComponent>
        );
    }
    async function loadMore() {
        var newPage = adminWithdrawalStore.incrementCurrentPage();
        await GetWithdrawalService({ page: newPage });
        setReload(!reload);
    }

    // solve
    const [withdrawalIndex, setWithdrawalIndex] = useState(0);
    const [withdrawalId, setWithdrawalId] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [solve, setSolve] = useState(false);

    return (
        <>
            <StandartBottomSheetComponent open={open} onDismiss={onDismiss} title='Список запросов'>
                <Items />
                {
                    adminWithdrawalStore.canLoad
                        ? <div>
                            <RetirementComponent />
                            <RaisedButtonComponent
                                onClick={loadMore}
                                placeholder="Показать еще"
                            />
                        </div>
                        : null
                }
            </StandartBottomSheetComponent>
            <SolveWithdrawalComponent open={solve} onDismiss={() => setSolve(false)} withdrawalId={withdrawalId} index={withdrawalIndex} customerId={customerId} />
        </>
    );
}

export default observer(RequestsWithdrawalComponent);
import { action, makeObservable, observable } from 'mobx';
import { RatingResponse } from '../model/RatingResponse';

class RatingStore {
    data: RatingResponse | null = null;

    constructor() {
        makeObservable(this, {
            data: observable,
            save: action
        });
    }
    save(value: RatingResponse) { this.data = value }
}

const ratingStore = new RatingStore();
export default ratingStore;

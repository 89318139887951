import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { CustomerListResponse } from "../model/CustomerListResponse";
import adminCustomerListStore from "../store/CustomerListStore";
export default async function GetCustomersService() {
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "customers",
        "accessToken": accessToken,
        "ownerId": adminCustomerListStore.ownerId,
        "status": adminCustomerListStore.status,
        "page": adminCustomerListStore.currentPage.toString()
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );

    // check result
    if (!result || !result.data) return;

    // save history
    if ((result.data as CustomerListResponse).customers.length === 0) {
        adminCustomerListStore.updateLoadAviable(false);
        if (adminCustomerListStore.currentPage !== 1) toast("Все данные загружены", { type: "success" });
        return result.data;
    }
    adminCustomerListStore.push((result.data as CustomerListResponse).customers);
    return result.data;
}
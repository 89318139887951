import { Container, styled } from "@mui/material";
import { ToastContainer } from "react-toastify";
import TemplateConfig from "../../../core/config/TemplateConfig";
import 'react-toastify/dist/ReactToastify.css';
import './custom.css';
import './bottomSheet.css';
import './drawer.css';
import LoadingModalComponent from "./LoadingModalComponent";
import { observer } from "mobx-react-lite";

const WrapperComponent = ({ children, minimized = false, background = false }: { children: React.ReactNode; minimized?: boolean; background?: boolean }) => {
    const Wrapper = styled('div')({
        backgroundColor: TemplateConfig.greyColorOpacity,
        backgroundImage: background ? `linear-gradient(135deg, ${TemplateConfig.blueColor}, ${TemplateConfig.pinkColor})` : undefined,
        height: 'var(--doc-height)',
        display: 'flex',
        width: '100%',
        backgroundSize: 'cover',
        WebkitBackgroundSize: 'cover'
    });

    const ContainerCompletelyWrap = styled(Container)(({ theme }) => ({
        backgroundColor: TemplateConfig.whiteColor,
        width: TemplateConfig.mobileMaxWidth,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            height: 'var(--doc-height)',
            marginTop: '0vh',
            marginBottom: '0vh',
        },
        height: '90vh',
        marginTop: '5vh',
        marginBottom: '5vh',
        padding: TemplateConfig.extraPadding
    }));

    const MiniContainer = styled(Container)(({ theme }) => ({
        backgroundColor: TemplateConfig.whiteColor,
        width: TemplateConfig.mobileMaxWidth,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        padding: TemplateConfig.extraPadding,
        maxHeight: '80vh',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            display: 'none'
        }
    }));

    const MiniContainerWrap = styled(Container)(({ theme }) => ({
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    }));

    return (
        <div>
            <Wrapper>
                {
                    !minimized ? (
                        <ContainerCompletelyWrap disableGutters>
                            {children}
                        </ContainerCompletelyWrap >
                    ) : (
                        <MiniContainerWrap>
                            <MiniContainer>
                                {children}
                            </MiniContainer>
                        </MiniContainerWrap>
                    )
                }
            </Wrapper >
            <ToastContainer
                style={{ zIndex: 1000, borderRadius: 0 }}
                position="bottom-right"
                autoClose={1500}
                closeOnClick={false}
                pauseOnHover={false}
                theme="colored"
                newestOnTop={true}
                closeButton={false}
                icon={false}
            />
            <LoadingModalComponent />
        </div>
    );
}

export default observer(WrapperComponent);
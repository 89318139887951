import { toast } from "react-toastify";
import ApplicationConfig from "../../../core/config/ApplicationConfig";
import AccessTokenVerifyService from "../../authenticate/service/AccessTokenVerifyService";
import GlobalRequestTemplate from "../../shared/service/GlobalRequestTemplate";
import { Customer } from "../model/CustomerListResponse";
import adminCustomerListStore from "../store/CustomerListStore";

export default async function CustomerStatusService({ customer, verify, block, index }: { customer: Customer; verify: string; block: string, index: number }) {
    console.log(block)
    // check values, get access token
    var accessToken = AccessTokenVerifyService();
    if (!accessToken) return;

    // request
    const params = {
        "module": "admin",
        "process": "customerStatus",
        "accessToken": accessToken,
        "pointCustomerId": customer.id,
        "verify": verify,
        "block": block
    };
    var result = await GlobalRequestTemplate(
        {
            link: ApplicationConfig.backendDirectory,
            params: params
        }
    );

    // check result
    if (!result || !result.data) return;

    // update store
    adminCustomerListStore.update(
        customer.forename,
        customer.surname,
        customer.email,
        index,
        verify === '1' ? '1' : customer.confirm.toString(),
        verify === '1'
            ? customer.blocked.toString()
            : block
    );
    toast("Данные успешно обновлен", { type: "success" });
}